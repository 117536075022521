import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";
import contacts from "../../ContactExample.json";

export const ModalContacts: React.FC = () => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [modalAnimation, setModalAnimation] = useState<string>('modal-contacts')
    const [show, setShow] = useState(false);


    const handleShow = () => {

        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
    }

    return (
        <>
            <div onClick={handleShow} className="user-account-options d-flex gap-3 p-3 account-border-bottom" style={{cursor: "pointer"}}>
                <img src="/images/fl-icon-contact.svg" />
                <p>Contacts</p>
            </div>

            <Modal className={modalAnimation} show={show} onHide={handleClose}>
                <Modal.Body className="p-0">
                    <button className="btn btn-modal-header" onClick={handleClose} />

                    {contacts.map((contact, index) => {
                        return (
                            <div key={index} className="user-contacts d-flex flex-column p-3 account-border-bottom">
                                <p>{contact.firstName} {contact.lastName}</p>
                                <p>{contact.phoneNumbers[0].number}</p>
                                <p>{contact.emails[0].email}</p>
                            </div>
                        );
                    })}

                </Modal.Body>
            </Modal>
        </>
    );
}