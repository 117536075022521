import React from "react";
import Carousel from 'react-multi-carousel';
import { useTranslation } from "react-i18next";
import { LifeEventType, MemoryType, LifeEvent } from "../AppType";
import { useContext, useEffect, useState } from "react";
import { fetchRecords, debug, ASSET_URL } from "../../utils/AppUtil";
import { LifeEventDetailed } from "./LifeEventDetailed";
import { AppContext } from "../../AppContext";
import { ModalCreateEvent } from "../Modals/ModalCreateEvent";
import { ModalCreateFamilyStory } from "../Modals/ModalCreateFamilyStory";
import { MemorialContext } from "../../MemorialContext";
import { MemoryDetailed } from "./MemoryDetailed";
import 'react-multi-carousel/lib/styles.css';

export const LifeStory: React.FC = () => {

    // Internationalization
    const { t } = useTranslation();
    
    // Authentication data inside AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data inside MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [memoriesState, setMemoriesState] = useState<React.ReactElement[]>();
    const [lifeEventState, setLifeEventState] = useState<React.ReactElement[]>();
    const [indicatorButtons, setIndicatorButtons] = useState<string[]>();

    // Life Event select
    const [selectedEventTitle, setSelectedEventTitle] = useState("");
    const [selectedEventDate, setSelectedEventDate] = useState("");
    const [selectedEventMessage, setSelectedEventMessage] = useState("");
    const [selectedEventLocations, setSelectedEventLocations] = useState<string[]>();
    const [selectedEventImages, setSelectedEventImages] = useState<string[]>();

    // Memory select
    const [memorySelected, setMemorySelected] = useState<boolean>(false);
    const [selectedMemoryTitle, setSelectedMemoryTitle] = useState("");
    const [selectedMemoryMessage, setSelectedMemoryMessage] = useState("");
    const [selectedMemoryImages, setSelectedMemoryImages] = useState<string[]>();

    const [memoryClicked, setMemoryClicked] = useState<number>(0);

    const [isChildClick, setIsChildClick] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [viewerState, setViewerState] = useState<boolean>(false);
    const [eventAdded, setEventAdded] = useState<number>(0);
    const [familyStoryReadMore, setFamilyStoryReadMore] = useState<boolean>(false);

    useEffect(() => {

        fetchRecords(commonData.token, `memorial/${memorialData.memorialId}/life_event_decade`, processTimelineData)
        .catch((error) => {
            setIsLoading(false);
            debug('fetchRecords error: ' + error, 'life_event');
        });

        fetchRecords(commonData.token, `memorial/${memorialData.memorialId}/memory`, processMemoriesData)
        .catch((error) => {
            setIsLoading(false);
            debug('fetchRecords error: ' + error, 'memory');
        });

        if (memorialData.role === "VIEWER" && memorialData.familyStory === null) {
            setViewerState(true)
        } else {
            setViewerState(false)
        }

    }, [memorialData, eventAdded]);

    const truncate20 = (text: string) => {
        return text.length > 23 ? text.substring(0, 20) + "..." : text;
    }

    const truncate100 = (text: string) => {
        return text.length > 103 ? text.substring(0, 100) + "..." : text;
    }

    const truncateFamilyStory = (text: string) => {
        if (familyStoryReadMore) {
            return text;
        } else {
            return text.length > 103 ? text.substring(0, 100) + "..." : text;
        }
    }

    const processMemoriesData = (responseJson: MemoryType[]) => {
        setMemoriesState(buildMemoriesListJSX(responseJson));
    }

    const buildMemoriesListJSX = (data: MemoryType[]): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
        let key: number = 0;

        data.forEach((memory: MemoryType) => {
            const pictureSrc: string = `${ASSET_URL}/public/picture/${memory.pictureIds[0]}`;
            
            result.push(
                <div key={key} className="memory-card d-flex flex-column" onClick={() => {
                        const images = memory.pictureIds.map(pictureId => pictureId);
                        setSelectedMemoryTitle(memory.title);
                        setSelectedMemoryMessage(memory.message);
                        setSelectedMemoryImages(images);
                        setMemorySelected(true);
                        setMemoryClicked(c => c + 1);
                    }}

                    data-bs-toggle="modal" data-bs-target="#MemoryDetailed" style={{cursor: "pointer"}} >
                    
                    <div className="memory-card-image" style={{backgroundImage: `url(${pictureSrc})`, mixBlendMode: 'luminosity'}}>
                        {/* <img src={pictureSrc} className="memory-image" style={{mixBlendMode: "luminosity"}}/> */}
                        <img src="/images/fl-play-button.svg" className="timeline-image-play" />
                    </div>
                    <div className="memories-note d-flex flex-column justify-content-between p-3">
                        <p className="mb-2">{truncate20(memory.title)}</p>
                        <p>From {memory.creator.firstname} {memory.creator.lastname}</p>
                    </div>
                </div>
            );
            key++;
        });

        return result;
    }

    const processTimelineData = (responseJson: LifeEventType[]) => {
        setIsLoading(true);
        setLifeEventState(buildLifeEventListJSX(responseJson));
        setIsLoading(false);
    }

    const timelineYearBadges = (lifeEvent: LifeEvent[]) => {
        let result = new Map<number, boolean>();

        for (let i = 0; i < lifeEvent.length; i++) {
            result.set(lifeEvent[i].eventYear, true);
        }

        return result;
    }
    
    const timelineImagesArray = [
        "/images/carouselPlaceholders/carousel-img-1.png",
        "/images/carouselPlaceholders/carousel-img-2.png",
        "/images/carouselPlaceholders/carousel-img-3.png",
        "/images/carouselPlaceholders/carousel-img-4.png",
    ];

    const buildLifeEventListJSX = (data: LifeEventType[]): React.ReactElement[] => {

        const result: React.ReactElement[] = [];
        let key: number = 1000;
        let indicatorButtonsArray: string[] = [];

        data.forEach((lifeEventType: LifeEventType) => {

            if ('DEFAULT_BIRTH' === lifeEventType.lifeEvents[0].type) {
                result.push(
                    <div key={key} className="timeline-item-initial ps-5">
                        <div className="timeline-date-breaker">{lifeEventType.decadeDisplay}</div>
                    </div>
                );
            } else {
                result.push(
                    <div key={key} className="timeline-item ps-5">
                        <div className="timeline-date-breaker">{lifeEventType.decadeDisplay}</div>
                    </div>
                );
            }

            let badges = timelineYearBadges(lifeEventType.lifeEvents);

            for (let i = 0; i < lifeEventType.lifeEvents.length; i++) {

                const currentEventId = lifeEventType.lifeEvents[i].lifeEventId;
                indicatorButtonsArray.push(currentEventId + '-ind');

                const messageText = truncate100(lifeEventType.lifeEvents[i].message);
                let display = badges.get(lifeEventType.lifeEvents[i].eventYear);

                // --- Image carousel items --- //

                //Set images PLACEHOLDER
                // let timelineImages: any[] = [];
                // for (let i = 0; i < timelineImagesArray.length; i++) {                    
                //     timelineImages.push(
                //         <img id={`img-${currentEventId}-${i}`} src={timelineImagesArray[i]} className="timeline-image" draggable={false} alt="" />
                //     );
                // }

                //Set images
                let timelineImages: any[] = [];
                for (let y = 0; y < lifeEventType.lifeEvents[i].pictureIds.length; y++) {
                    timelineImages.push(
                        <img id={`img-${currentEventId}-${y}`} src={`${ASSET_URL}/public/picture/${lifeEventType.lifeEvents[i].pictureIds[y]}`} className="timeline-image" draggable={false} alt="" />
                    );
                }

                // Set indicators
                let timelineImageIndicators: any[] = [];
                for (let y = 0; y < lifeEventType.lifeEvents[i].pictureIds.length; y++) {
                    timelineImageIndicators.push(
                        <span id={`ind-${currentEventId}-${y}`} className={`mx-1 `} />
                    );
                }
  
                if ('DEFAULT_BIRTH' === lifeEventType.lifeEvents[i].type) {
                    result.push(
                        <div key={lifeEventType.lifeEvents[i].lifeEventId}>      
                            <div className="timeline-item ps-5 py-4">

                                {display &&
                                    <div className="timeline-year-badge">
                                        <p>{lifeEventType.lifeEvents[i].eventYear}</p>
                                    </div>
                                }
    
                                <div className="timeline-card d-flex flex-column">
                                    <div className="p-3" style={{position: 'relative'}}>
                                        <div className="timeline-note">
                                            <p>{lifeEventType.lifeEvents[i].title}</p>
                                            <p className="timeline-note-date mb-2">{lifeEventType.lifeEvents[i].eventDateDisplay}</p>
                                            <p>{messageText}</p>
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    );
                } else if ('USER_CREATED' === lifeEventType.lifeEvents[i].type){
                    result.push(
                        <div key={lifeEventType.lifeEvents[i].lifeEventId}>
                        
                            <div className="timeline-item ps-5 py-4">

                                {display &&
                                    <div className="timeline-year-badge">
                                        <p>{lifeEventType.lifeEvents[i].eventYear}</p>
                                    </div>
                                }

                                <div className="timeline-card d-flex flex-column" >

                                    <Carousel
                                        additionalTransfrom={0}
                                        arrows={false}
                                        autoPlaySpeed={3000}
                                        centerMode={false}
                                        className=""
                                        containerClass=""
                                        dotListClass=""
                                        draggable
                                        focusOnSelect={false}
                                        infinite
                                        itemClass=""
                                        keyBoardControl
                                        minimumTouchDrag={80}
                                        pauseOnHover
                                        renderArrowsWhenDisabled={false}
                                        renderButtonGroupOutside={false}
                                        renderDotsOutside={false}
                                        responsive={{
                                            desktop: {
                                            breakpoint: {
                                                max: 3000,
                                                min: 1024
                                            },
                                            items: 1
                                            },
                                            mobile: {
                                            breakpoint: {
                                                max: 464,
                                                min: 0
                                            },
                                            items: 1
                                            },
                                            tablet: {
                                            breakpoint: {
                                                max: 1024,
                                                min: 464
                                            },
                                            items: 1
                                            }
                                        }}
                                        rewind={false}
                                        rewindWithAnimation={false}
                                        rtl={false}
                                        shouldResetAutoplay
                                        showDots
                                        sliderClass=""
                                        slidesToSlide={1}
                                        swipeable
                                    >
                                        {timelineImages}
                                        {/* {lifeEventType.lifeEvents[i].} */}
                                    </Carousel>
                                    
                                    <div onClick={() => {
                                        
                                        const locationDescriptions = lifeEventType.lifeEvents[i].locations.map(location => location.description);
                                        const images = lifeEventType.lifeEvents[i].pictureIds.map(pictureId => pictureId);

                                        if (!isChildClick) {
                                            setSelectedEventTitle(lifeEventType.lifeEvents[i].title);
                                            setSelectedEventDate(lifeEventType.lifeEvents[i].eventDateDisplay);
                                            setSelectedEventMessage(lifeEventType.lifeEvents[i].message);
                                            setSelectedEventLocations(locationDescriptions);
                                            setSelectedEventImages(images);
                                        }}}

                                        data-bs-toggle={!isChildClick ? 'modal' : undefined} data-bs-target={!isChildClick ? '#lifeEventDetailed' : undefined} style={{cursor: "pointer"}} >

                                        {/* {lifeEventType.lifeEvents[i].locations.map((location, index) => {
                                            return (
                                                <div key={index} className="timeline-location-pill d-flex gap-2 px-2 py-1 mx-3 mt-3">
                                                    <img src="/images/fl-map-pin.svg" />
                                                    <p>{location.description}</p>
                                                </div>
                                            )
                                        })} */}

                                        {lifeEventType.lifeEvents[i].locations[0] &&
                                            <div className="timeline-location-pill d-flex gap-2 px-2 py-1 mx-3 mt-3">
                                                <img src="/images/fl-map-pin.svg" />
                                                <p>{lifeEventType.lifeEvents[i].locations[0].description}</p>
                                            </div>
                                        }
                                        

                                        <div className="p-3" style={{position: 'relative'}}>
                                            <div className="timeline-note">
                                                <p>{lifeEventType.lifeEvents[i].title}</p>
                                                <p className="timeline-note-date mb-2">{lifeEventType.lifeEvents[i].eventDateDisplay}</p>
                                                <p>{messageText}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    );
                } else if ('DEFAULT_DEATH' === lifeEventType.lifeEvents[i].type) {
                    result.push(
                        <div key={lifeEventType.lifeEvents[i].lifeEventId}>
    
                            <div className="timeline-item ps-5 py-4">

                                {display &&
                                    <div className="timeline-year-badge">
                                        <p>{lifeEventType.lifeEvents[i].eventYear}</p>
                                    </div>
                                }

                                <div className="timeline-tombstone-top card-container-top d-flex justify-content-center" style={{backgroundImage: "url('/images/tombstone-placeholder.png')"}}>
                                    <div className="tombstone-text timeline">
                                        <p>{memorialData.firstName}<br />{memorialData.lastName}</p>
                                        <p>{memorialData.birthDateDisplay}<br />{memorialData.deathDateDisplay}</p>
                                    </div>
                                </div>
                                <div className="timeline-tombstone-bottom card-container-bottom">
                                    <div className="timeline-card-tombstone" style={{position: 'relative'}}>
                                        <div className="timeline-note">
                                            <p>{ t("Timeline.timelineDeath", {name: `${memorialData.firstName}`, ns: "components"}) }</p>
                                            <p className="timeline-note-date">{lifeEventType.lifeEvents[i].eventDateDisplay}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }

                if (display) {
                    badges.set(lifeEventType.lifeEvents[i].eventYear, false);
                }
            }
            key++;
        });

        setIndicatorButtons(indicatorButtonsArray);
        return result;
    }

    const newEventAdded = () => {
        setEventAdded(eventAdded + 1);
    }

    if (isLoading) {
        return(
            <div className="d-flex justify-content-center align-items-center" style={{height: "100dvh"}}>
                <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className={`p-${viewerState ? "0" : "3"}`}>
                {memorialData.role !== "VIEWER" &&
                    <ModalCreateEvent newEventAdded={newEventAdded} />
                }

                {(memorialData.role === "VIEWER" || memorialData.role === "EDITOR") && memorialData.familyStory === null ?
                    <></>
                    :
                    <>
                        {/* Write a family story */}
                        <div className="fl-card mb-4" style={{position: "relative"}}>

                            {(memorialData.familyStory && (memorialData.role === "CREATOR" || memorialData.role === "ADMIN")) &&
                                <img src="/images/fl-pen.svg" className="timeline-edit" width="32px" height="32px" />
                            }

                            <p className="timeline-obituary-title mb-2">{ t("Timeline.obituaryTitle", {ns: "components"}) }</p>

                            <p className="timeline-obituary-subtitle mb-3">
                                {memorialData.familyStory == null ?
                                    t("Timeline.obituarySubTitle", {name: `${memorialData.lastName}`, ns: "components"}):
                                    truncateFamilyStory(memorialData.familyStory)
                                }
                            </p>
                            
                            {memorialData.familyStory !== null ?
                                <p className="btn-txt" onClick={() => setFamilyStoryReadMore(c => !c)} style={{float: "right"}}>{ t("Timeline.readObituary", {ns: "components"}) }</p> :
                                <ModalCreateFamilyStory memorialState={memorialData} />
                            }
                        </div>
                    </>
                }
            </div>

            {memoriesState?.length !== 0 ?
                <div className="timeline-memories-wrapper d-flex flex-column gap-3 py-3 mb-5">
                    <p className="memories-heading ps-3">{ t("Timeline.memoriesTitle", {ns: "components"}) }</p>
                    <div className="timeline-memories d-flex gap-3 mb-3">
                        {memoriesState}
                    </div>
                </div> : ""
            }
            

            <div className="d-flex flex-column ps-5 pe-3">
                {lifeEventState}
            </div>

            <MemoryDetailed title={selectedMemoryTitle} message={selectedMemoryMessage} images={selectedMemoryImages} memorySelected={memorySelected} memoryClicked={memoryClicked} />
            <LifeEventDetailed title={selectedEventTitle} message={selectedEventMessage} eventDateDisplay={selectedEventDate} locations={selectedEventLocations} images={selectedEventImages} />
        </>
    );
}