import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { Modal } from "react-bootstrap";
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";

export const ModalAccountDrawer: React.FC = () => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [creator, setCreator] = useState<string>(memorialData.creator.firstname + " " + memorialData.creator.lastname)
    
    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (commonData.userId === memorialData.creator.userId) {
            setCreator('You');
        }
    }, [commonData, memorialData])

    const handleShow = () => {
        setModalAnimation('modal-account-drawer modal-account-drawer-background animate__animated animate__slideInUp');
        setShow(true);
    }

    const handleClose = () => {
        setModalAnimation('modal-account-drawer modal-account-drawer-background animate__animated animate__slideOutDown');
        setTimeout(() => {
            setShow(false);
        }, 300);
    }

    return (
        <>
            <img src="/images/fl-elips.svg" onClick={handleShow} style={{cursor: "pointer"}} />

            <Modal className={modalAnimation} show={show} onHide={handleClose}>
                <Modal.Header className="" onClick={handleClose}>
                    <div className="modal-account-drawer-close"></div>
                </Modal.Header>
                
                <Modal.Body className="d-flex flex-column gap-2">

                        <div className="account-drawer-txt d-flex flex-column gap-1">
                            <p>This memorial was created by</p>
                            <p>{creator}</p>
                        </div>

                </Modal.Body>
            </Modal>
        </>
    );
}





