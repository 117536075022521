interface Window {
    deferredPrompt: any;
}

export type Application = {
    auth: Auth,
    state: {
        memorialPictureId: string,
    }
}

// Auth
export type Auth = {
    token: string,
    userId: string,
    firstName: string,
    lastName: string,
    username: string,
    email: string,
    cellphone: string
}

// Data
export type PageResultType = { 
    data: any[],
    pageNum: number,
    pageSize: number,
    numRecords: number,
    numPages: number
}

// Memorial Tabs - represented as buttoms at the bottom of the Memorial Journey.
export enum MemorialTab {
    home = 'pageHome',
    tab1 = 'pageLifeStory',
    tab2 = 'pageTreeOfLife',
    tab3 = 'pageTombstone'
}

// Forever Loved Days
export type ForeverLovedDaysType = {
    userId: string,
    userFirstname: string,
    userLastname: string,
    relationship: string,
    memorialId: string,
    memorialFirstName: string,
    memorialLastName: string,
    memorialPictureId: string,
    memorialCoverPictureId: string,
    date: string,
    dateType: string
}

// Memorial Get
export type MemorialType = {
    memorialId: string,
    firstName: string,
    lastName: string,
    familyStory: string,
    birthDate: string,
    birthDateDisplay: string,
    birthYear: number,
    deathDate: string,
    deathDateDisplay: string,
    deathYear: number,
    memorialPictureId: string,
    coverPictureId: string,
    creator: {
        userId: string,
        firstname: string,
        lastname: string,
        email: string
    },
    role: string,
    religiousBelief: {
        religiousBeliefId: string,
        name: string
    }
}

// Memorial Create
export type CreateMemorialType = {
    firstName: string,
    lastName: string,
    gender: string,

    birthDateDay: string,
    birthDateMonth: string,
    birthDateYear: string,

    deathDateDay: string,
    deathDateMonth: string,
    deathDateYear: string,

    birthDate: string,
    deathDate: string,
    obituary: string,
    religiousBeliefId: string,
    userMemorialRelationshipId: string

    locationDesc: string,
    locationLong: number,
    locationLat: number
}

// Memorial Update
export type UpdateMemorialType = {
    firstName: string,
    lastName: string,
    birthDate: string,
    deathDate: string,
    obituary: string,
    memorialPictureId: string,
    role: string,
    religiousBeliefId: null,
    userMemorialRelationshipId: null
}

// Family Story Update
export type UpdateFamilyStoryType = {
    familyStory: string,
}

// Memorial Picture Update
export type UpdateMemorialPictureType = {
    memorialPictureId: string,
    coverPictureId: string,
    religiousBeliefId: null
}

// Memorial Profile Update
export type UpdateMemorialPicture = {
    memorialPictureId: string,
    religiousBeliefId: null
}

// Memorial Cover Update
export type UpdateCoverPicture = {
    coverPictureId: string,
    religiousBeliefId: null
}

// Picture upload
export type PictureUploadType = {
    memorialId: string,
    file: any
}

// Picture update
export type PictureUpdateType = {
    pictureId: string,
    file: any
}

// Picture Gallery
export type PictureGalleryType = {
    pictureId: string,
    seqNum: null
}

// Subscriber
export type UserType = {
    firstname: string,
    lastname: string,
    mobileNumber: string,
    email: string,
    password: string,
    dateOfBirth: string
}

// Leaf
export type LeafDonationType = {
    leafId: string,
    amount: number,
    amountDisplay: string,
    user: {
      userId: string,
      firstname: string,
      lastname: string,
      email: string,
      cellphone: string
    },
    charity: {
      charityId: string,
      name: string
    },
    currencySymbol: string,
    leafType: {
        leafTypeId: string,
        name: string,
        color: string,
        numDays: number
    }
    invoice: {
        invoiceId: string,
        currency: {
            currencyId: string,
            symbol: string,
            name: string
        }
        amount: number,
        amountDisplay: string
    }
}

export type LeafDonateType = {
    leafTypeId: string,
    paymentProviderId: string,
    currencyId: string,
    amount: 0
}

export type leafType = {
    leafTypeId: string,
    name: string,
    color: string,
    numDays: number
}

// Message
export type MessageType =  {
    messageId: string,
    message: string,
    messageDate: string,
    messageDateDisplay: string,
    subscriber: {
        subscriberId: string,
        firstName: string,
        lastName: string,
        email: string
    }
}

// Memories
export type MemoryType = {
    memoryId: string,
    status: string,
    title: string,
    message: string,
    creator: {
        userId: string,
        firstname: string,
        lastname: string,
        email: string
    },
    pictureIds: [
        string
    ],
    extra: {
        additionalProp1: {},
        additionalProp2: {},
        additionalProp3: {}
    }
}

// Life Event Create
export type MemoryCreateType = {
    title: string,
    message: string,
    pictureIds: string[],
    extra: {
        additionalProp1: {},
        additionalProp2: {},
        additionalProp3: {}
    }
  }

// Life EventType
export type LifeEventType = {
    decadeDisplay: string,
    lifeEvents: LifeEvent[]
}

// Life Event
export type LifeEvent = {
    lifeEventId: string,
    type: string,
    title: string,
    message: string,
    eventDate: string,
    eventDateDisplay: string,
    eventYear: number,
    pictureIds: [],
    locations: Location[]
}

interface Location {
    latitude: number;
    longitude: number;
    description: string;
}

// Life Event Create
export type LifeEventCreateType = {
    title: string,
    message: string,
    eventDate: string,
    eventDateDay: string,
    eventDateMonth: string,
    eventDateYear: string,
    locations: Location[]
}