import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { Modal } from "react-bootstrap";
import { CreateLifeEvent } from "./CreateLifeEvent";
import { CreateMemory } from "./CreateMemory";
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";
import 'animate.css';

type ModalProps = {
    newEventAdded: () => void;
}

export const ModalCreateEvent: React.FC<ModalProps> = ({newEventAdded}) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;
    
    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [cardOneAnimation, setCardOneAnimation] = useState<string>('')
    const [cardTwoAnimation, setCardTwoAnimation] = useState<string>('')
    const [show, setShow] = useState(false);

    const [modalSuccess, setModalSuccess] = useState<boolean>(false);
    const [modalFailed, setModalFailed] = useState<string>("");
    const [createdEventType, setCreatedEventType] = useState<string>("");

    const [eventSelected, setEventSelected] = useState<number>(0);
    const [selectLifeEvent, setSelectLifeEvent] = useState<boolean>(false);
    const [selectMemory, setSelectMemory] = useState<boolean>(false);
    const selectedBorder: string = "2px solid white";

    useEffect(() => {
        setEventSelected(0);
        setCardOneAnimation('');
        setCardTwoAnimation('');
        setSelectLifeEvent(false);
        setSelectMemory(false);
    }, [show])

    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
        setTimeout(() => {
            setModalSuccess(false);
        }, 500);
    }

    const handleLifeEventClick = () => {
        setSelectLifeEvent(true);
        setSelectMemory(false);
    }

    const handleMemoryClick = () => {
        setSelectMemory(true);
        setSelectLifeEvent(false);
    }

    const handleEventSelected = () => {
        if (selectLifeEvent) {
            setCardOneAnimation('animate__animated animate__flipOutY');
            setTimeout(() => {
                setEventSelected(1);
            }, 500);
        } else if (selectMemory) {
            setCardTwoAnimation('animate__animated animate__flipOutY');
            setTimeout(() => {
                setEventSelected(2);
            }, 500);
        }
    }

    const triggerLifeEvent = (value: string) => {
        setTimeout(() => {
            setCardOneAnimation(value);
            setEventSelected(0);
        }, 500);
    };

    const triggerMemory = (value: string) => {
        setTimeout(() => {
            setCardTwoAnimation(value);
            setEventSelected(0);
        }, 500);
    };

    const handleChangeEvent = () => {
        setSelectMemory(true);
        setSelectLifeEvent(false);

        setTimeout(() => {
            setCardTwoAnimation('animate__animated animate__flipOutY');
            setTimeout(() => {
                setEventSelected(2);
            }, 500);
        }, 1000);
    }

    const handleEventCreated = (eventType: string, success: boolean, error: string) => {
        setCreatedEventType(eventType);
        if (success) {
            setModalSuccess(true);
            newEventAdded();
        } else if (!success) {
            setModalSuccess(false);
            setModalFailed(error);
        }
    }

    return (
        <>

            <div className="timeline-btn mb-4 mt-2" onClick={handleShow} style={{cursor: "pointer"}}>
                <img src="/images/fl-plus-lg.svg" />
                <p className="timeline-btn-title">{ t("Timeline.addLifeEvent", {name: `${memorialData.firstName}`, ns: "components"}) }</p>
            </div>

            <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose}>
                <div className="modal-body-event-tier-1">
                    <div className="d-flex justify-content-center">
                        <p className="modal-title">New Event</p>
                        <button className="btn btn-modal-header" onClick={handleClose} />
                    </div>

                    <div className="d-flex flex-column gap-4">
                        <p className="modal-heading">Let’s create a new event</p>

                        <div className={`add-event-widget ${cardOneAnimation}`} onClick={handleLifeEventClick} style={{border: selectLifeEvent ? selectedBorder : ""}}>
                            <img src="/images/add-life-event.svg" width={23} height={28} />
                            <p className="title">Life Event</p>
                            <p className="subtitle">These events are for significant moments in {memorialData.firstName}’s life. They require a date.</p>
                        </div>
                        
                        <div className={`add-event-widget ${cardTwoAnimation}`} onClick={handleMemoryClick} style={{border: selectMemory ? selectedBorder : ""}}>
                            <img src="/images/add-memory.svg" width={34} height={25} />
                            <p className="title">Memories</p>
                            <p className="subtitle">These events are for memories you have of {memorialData.firstName}. They do not require a date.</p>
                        </div>
                    </div>

                    <button className="btn btn-modal-footer" onClick={handleEventSelected}>Next</button>

                </div>
    

                {eventSelected === 1 && <CreateLifeEvent triggerLifeEvent={triggerLifeEvent} changeEvent={handleChangeEvent} handleEventCreated={handleEventCreated} />}
                {eventSelected === 2 && <CreateMemory triggerMemory={triggerMemory} handleEventCreated={handleEventCreated} />}

                {/* {modalSuccess && 
                    <div className="modal-success-div">
                        <div className="d-flex flex-column gap-2 align-items-center justify-content-center" style={{height: "85%"}}>
                            <img src="/images/fl-login-tick.svg" />
                            <div className="login-success-txt d-flex flex-column gap-1">
                                <p>{createdEventType} added</p>
                                <p></p>
                            </div>
                        </div>

                        <button onClick={handleClose} className="btn fl-btn-modal-bottom">OK</button>
                    </div>
                } */}

                {modalFailed !== "" && 
                    <div className="modal-success-div animate__animated animate__slideInRight">
                        <div className="d-flex flex-column gap-2 align-items-center justify-content-center" style={{height: "85%"}}>
                            <img src="/images/fl-login-tick.svg" />
                            <div className="login-success-txt d-flex flex-column gap-1">
                                <p>Oops, something went wrong.</p>
                                <p>Error: {modalFailed}</p>
                            </div>
                        </div>

                        <button onClick={handleClose} className="btn fl-btn-modal-bottom">OK</button>
                    </div>
                }
            </Modal>
        </>
    );
}





