import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { MemorialContext } from "../../MemorialContext";
import { PictureGalleryType, PictureUpdateType, PictureUploadType } from "../AppType";
import { ASSET_URL, fetchImage, fetchRecords, postFormData, postFormPictureFormData } from "../../utils/AppUtil";
import { Modal } from "react-bootstrap";
import { useSpring} from '@react-spring/web';
import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react'
import AvatarEditor from 'react-avatar-editor';
import imageCompression from "browser-image-compression";
import { useTranslation } from "react-i18next";
import 'animate.css';

const useGesture = createUseGesture([dragAction, pinchAction]);

type ModalProps = {
    endpoint: string
    modalClose: () => void;
}

export const ModalProfilePicture: React.FC<ModalProps> = ({modalClose, ...props}) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Cropping & zooming refs
    const pinchRef = useRef<HTMLDivElement>(null!)
    const editorRef = useRef<AvatarEditor>(null!);
    const pictureInputRef = useRef<HTMLInputElement>(null!);

    // formState for loading image to gallery
    const initFormUpload: PictureUploadType = {
        memorialId: memorialData.memorialId,
        file: null
    }
    const [formStateUpload, setFormStateUpload] = useState<PictureUploadType>(initFormUpload);

    // formState for updating existing image
    const initFormUpdate: PictureUpdateType = {
        pictureId: "",
        file: null
    }
    const [formStateUpdate, setFormStateUpdate] = useState<PictureUpdateType>(initFormUpdate);

    // Picture type ('Memorial' or 'Cover')
    const [pictureType, setPictureType] = useState<string>("");

    // Modal handlers
    const [show, setShow] = useState(false);
    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [spinnerLoading, setspinnerLoading] = useState<boolean>(false);
    const [modalSuccess, setModalSuccess] = useState<boolean>(false);

    // Gallary & Editor states
    const [newImage, setNewImage] = useState<File>();
    const [newImageArray, setNewImageArray] = useState<File[]>([]);
    const [imageSelected, setImageSelected] = useState<boolean>(false);
    const [zoom, setZoom] = useState<number>(1);

    // Text to update if image already exists
    const [uploadMemorialText, setUploadMemorialText] = useState<string>("Upload");
    const [uploadCoverText, setUploadCoverText] = useState<string>("Upload");

    // Initial settings for touch-zoom functionality
    const [style, api] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: zoom,
        rotateZ: 0,
    }));

    // useEffect to prevent default event behaviour for touch actions on component mount
    useEffect(() => {
        const handler = (e: Event) => e.preventDefault();
        document.addEventListener('gesturestart', handler);
        document.addEventListener('gesturechange', handler);
        document.addEventListener('gestureend', handler);

        // return runs cleanup on unmount to remove event listeners
        return () => {
            document.removeEventListener('gesturestart', handler);
            document.removeEventListener('gesturechange', handler);
            document.removeEventListener('gestureend', handler);
        };
    }, []);

    // useEffect to post newly created images to the memorial record
    useEffect(() => {
        if (newImage) {
            postFormData(
                commonData.token,
                `memorial/${memorialData.memorialId}`,
                memorialData,
                (response: any) => { // Success
                },
                (error: any) => { // Error
                    console.log("Error:", error);
                }
            )
        }

        // Fetch gallery data
        // fetchRecords(commonData.token, `picture/memorial/${memorialData.memorialId}`, processData);

        // Update text if image exists
        if (memorialData.memorialPictureId) {
            setUploadMemorialText("Change")
        }
        if (memorialData.coverPictureId) {
            setUploadCoverText("Change")
        }

    }, [memorialData]);

    // Build image array
    const buildImageArray = (): React.ReactElement[] => {
        return newImageArray.map((file, index) => {
            const imageSrc = URL.createObjectURL(file);

            return <div key={`new-${index}`} className={`gallery-image-wrapper ${file === newImage ? 'selected' : ''}`}>
                       <img src="/images/fl-icon-bin.svg" className="gallery-image-remove" onClick={handleImageRemove} />
                       <img src={imageSrc} style={{borderRadius: "8px"}} width={124} height={124} />;
                   </div>
        });
    }

    const handleImageRemove = () => {
        setNewImage(undefined);
        setNewImageArray([]);
    }

    // Fetch selected image
    const selectImage = (pictureId: string) => {
        fetchImage(commonData.token, `picture/` + pictureId, processSelectImage);

        setFormStateUpdate((formStateUpdate) => ({
            ...formStateUpdate,
            pictureId: pictureId
        }));
        setImageSelected(true);

        console.log("Selected image ID:", pictureId);
    }

    // Add selected image to cropping tool
    const processSelectImage = (response: File) => {
        setNewImage(new File([response], response.name));
    }

    // Disable DOM zooming to allow pinching on image
    const deactivateZoomDiv = document.getElementById('deactivateZoom');
    deactivateZoomDiv?.addEventListener('touchmove', function(event) {
        const touchEvent = event as TouchEvent & { scale: number };
        if (touchEvent.scale !== 1) {
            event.preventDefault();
        }
    }, {passive: false});

    // Touch pinch actions
    useGesture(
        {
            onDrag: ({pinching, cancel, offset: [x, y], ...rest}) => {
                if (pinching) return cancel();
                api.start({ x, y });
            },
            onPinch: ({offset: [s], first}) => {
                if (first) {
                    const {width, height, x, y} = pinchRef.current!.getBoundingClientRect();
                    const tx = x + width / 2;
                    const ty = y + height / 2;
                    api.start({ x: -tx, y: -ty });
                }
                setZoom(s);
            },
        },
        {
            target: pinchRef,
            drag: {from: () => [style.x.get(), style.y.get()]},
            pinch: {scaleBounds: {min: 0.25, max: 5}, rubberband: false},
        }
    );

    // Mousewheel scroll Zoom
    const scaleStep = 0.1;
    document.getElementById('selectedImageContainer')?.addEventListener('wheel', (event) => {
        event.preventDefault();
        
        if (event.deltaY < 0) {
            setZoom(zoom + scaleStep);
        } else {
            setZoom(zoom - scaleStep);
        };
    });

    // Open modal
    const handleShow = (type: string) => {
        setPictureType(type);
        setShow(true);
    }

    // Close modal
    const handleClose = () => {
        modalClose();
        setShow(false);
        setModalSuccess(false);
    }

    // Form change
    const handleFormChange = (e: React.ChangeEvent<any>) => {
        
        if (e.target.type === "file" && e.target.files != null) {
            setNewImage(e.target.files[0]);
            setFormStateUpload((formState) => ({
                ...formState, 
                [e.target.id]: e.target.files[0]
            }));

            setNewImageArray(newImageArray => [...newImageArray, e.target.files[0]] );
            setImageSelected(false);
        }
    }

    // Image cropping
    const handleCrop = async (): Promise<Blob> => {
        const canvasImage: HTMLCanvasElement = editorRef.current.getImage();
    
        return new Promise((resolve, reject) => {
            canvasImage.toBlob((blob: any) => {
                if (!blob) {
                    reject(new Error("Failed to convert canvas to Blob"));
                    return;
                }
    
                let name = "";
                if (pictureInputRef?.current?.files?.length) {
                    name = pictureInputRef.current.files[0].name;
                }
    
                if (imageSelected) {
                    setFormStateUpdate((formStateUpdate): PictureUpdateType => {
                        const form = { ...formStateUpdate,
                            file: blob,
                        };
                        return form;
                    });
                } else {
                    setFormStateUpload((formStateUpload): PictureUploadType => {
                        const form = { ...formStateUpload, file: blob };
                        return form;
                    });
                }
    
                resolve(blob);
            });
        });
    }

    // Image compression
    const handleCompression = async (file: any): Promise<Blob> => {
        console.log('originalFile instanceof Blob', file instanceof Blob); // true
        console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
    
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };
    
        try {
            const compressedFile = await imageCompression(file, options);
            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    
            return compressedFile;
        } catch (error: any) {
            console.log(error.message);
            throw error;
        }
    }

    const handleAddImage = () => {
        pictureInputRef.current.click();
    }

    // Post image data to gallery
    const handlePostUpload = async (compressedFile: Blob) => {

        // Ensure form state has the latest file
        console.log('Posting Form State:', formStateUpload);
        console.log('Posting File:', compressedFile);

        const formData = new FormData();
        formData.set("memorialId", memorialData.memorialId);
        formData.set("file", compressedFile);
    
        postFormPictureFormData(
            commonData.token,
            props.endpoint,
            formData,
            (responseData: any) => {
                console.log("Multi-post Success", responseData);

                if (pictureType === "Memorial") {
                    setMemorialData((memorialData) => ({
                        ...memorialData,
                        memorialPictureId: responseData.pictureId
                    }));

                } else if (pictureType === "Cover") {
                    setMemorialData((memorialData) => ({
                        ...memorialData,
                        coverPictureId: responseData.pictureId
                    }));
                }

                setFormStateUpload(initFormUpload);
            },
            (response: any) => { // error
                console.log("Multi-post Fail Upload", response);
            }
        );
    }

    // Post image data to gallery
    const handlePostUpdate = async (compressedFile: Blob) => {

        // Ensure form state has the latest file
        console.log('Posting Form State:', formStateUpdate);
        console.log('Posting File:', compressedFile);

        const formData = new FormData();
        // formData.set("pictureId", formStateUpdate.pictureId);
        formData.set("file", compressedFile);
    
        postFormPictureFormData(
            commonData.token,
            `${props.endpoint}/${formStateUpdate.pictureId}`,
            formData,
            (responseData: any) => {
                console.log("Multi-post Success", responseData);

                if (pictureType === "Memorial") {
                    setMemorialData((memorialData) => ({
                        ...memorialData,
                        memorialPictureId: responseData.pictureId
                    }));

                } else if (pictureType === "Cover") {
                    setMemorialData((memorialData) => ({
                        ...memorialData,
                        coverPictureId: responseData.pictureId
                    }));
                }

                setFormStateUpdate(initFormUpdate);
            },
            (response: any) => { // error
                console.log("Multi-post Update Fail", response);
            }
        );
    }

    // Handle click to crop, compress, push.
    const handleClick = async (): Promise<void> => {
        try {
            setspinnerLoading(true);
            const croppedBlob = await handleCrop();
            console.log('Cropping completed successfully.');
            console.log('CROPPED:', croppedBlob);
    
            const compressedBlob = await handleCompression(croppedBlob);
            console.log('Compression completed successfully.');
            console.log('COMPRESSED:', compressedBlob);
    
            // Directly call handlePost* and pass the compressed file
            if (imageSelected) {
                await handlePostUpdate(compressedBlob);
            } else {
                await handlePostUpload(compressedBlob);
            }

            setModalSuccess(true);
    
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    return (
        <>
            <p className="add-image-txt" onClick={() => handleShow('Memorial')}>{uploadMemorialText} Memorial Picture</p>
            <hr className="hr-modal-separator" />
            <p className="add-image-txt" onClick={() => handleShow('Cover')}>{uploadCoverText} Cover Photo</p>

            <form>
                <input id="memorialId" name="memorialId" type="hidden" value={memorialData.memorialId} />
                <input ref={pictureInputRef} id="file" name="file" type="file" accept="image/*" onChange={handleFormChange} style={{display: "none"}} />
                <button id="formSubmit" type="submit" style={{display: "none"}}>Submit</button>

                <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose}>
                    <Modal.Header>
                        <p className="modal-title">Add a {pictureType} Picture</p>
                        <button className="btn btn-modal-header" onClick={handleClose} />
                    </Modal.Header>

                    <Modal.Body className="p-0">

                        {newImage ?
                            <>
                                <div ref={pinchRef} id="deactivateZoom" style={{height: "100vw"}}>
                                    <div className="d-flex justify-content-center">
                                        <div className="image-canvas-grid" />

                                        {spinnerLoading &&
                                            <div className="image-canvas-loading animate__animated animate__fadeIn animate__faster">
                                                <img src="images/creating-memorial.svg" />
                                                <p className="loading-ellipsis">Uploading Image</p>
                                            </div>
                                        }

                                        <div className="memorial-img-crop-wrapper d-flex justify-content-center align-items-center">
                                            <div id="selectedImageContainer" className="memorial-img-crop-preview">
                                                {newImage &&
                                                    <AvatarEditor
                                                        ref={editorRef}
                                                        image={newImage}
                                                        border={0}
                                                        borderRadius={pictureType === 'Memorial' ? 500 : 0}
                                                        scale={zoom}
                                                        rotate={0}
                                                        style={{
                                                            transform: `translate(${style.x}px, ${style.y}px) scale(${style.scale}) rotate(${style.rotateZ}deg)`,
                                                            touchAction: "pinch-zoom",
                                                            width: "100vw",
                                                            height: "100vw"
                                                        }}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex flex-column w-100">
                                    <div className="d-flex justify-content-center gap-2 p-3">
                                        <img src="/images/pinch-zoom.svg" />
                                        <p className="pich-zoom-text">Pinch & zoom to crop</p>
                                    </div>
                                    
                                    <div className="image-gallery d-flex flex-row flex-wrap">
                                        {buildImageArray()}
                                    </div>
                                    
                                </div>
                                
                                <button className="btn fl-btn-modal-bottom button-absolute" onClick={handleClick} type="button">Done</button>
                            </>
                            :
                            <>
                                <div className="d-flex justify-content-center align-items-center h-100">

                                    <div className="add-photo-btn" onClick={handleAddImage}>
                                        <img src="/images/fl-plus-lg.svg" width={32} height={32} />
                                        <p>Click to add an image</p>
                                    </div>

                                </div>
                            </>
                        }
                    
                    </Modal.Body>

                    {modalSuccess && 
                        <div className="modal-success-div animate__animated animate__slideInRight">
                            <div className="d-flex flex-column gap-2 align-items-center justify-content-center" style={{height: "85%"}}>
                                <img src="/images/fl-login-tick.svg" />
                                <div className="login-success-txt d-flex flex-column gap-1">
                                    <p>{pictureType} Picture Added</p>
                                    <p></p>
                                </div>
                            </div>

                            <button onClick={handleClose} className="btn fl-btn-modal-bottom button-absolute">Close</button>
                        </div>
                    }
                </Modal>
            </form>
        </>

    );
}