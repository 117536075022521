import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Languagedetector from "i18next-browser-languagedetector";

// Initialise i18next.
// https://www.youtube.com/watch?v=SA_9i4TtxLQ
i18next
	.use(initReactI18next)
	.use(Languagedetector)
	.init({
		debug: true,
		lng: "en",
		fallbackLng: ['en'],
		fallbackNS: "common",
		resources: {

			// ENGLISH
			en: {
				nativeName: "English",
				translation: { 
					key: "Good day Forever Loved.",
					anotherKey: {
						key: 'Another key.',
						description: 'Another key description.'
					},
					error: {
						404: "Unfortunately we are unable to find what you are looking for :(",
						unknown: "Oops, that was not supposed to happen."
					}
				},
				common: {
					save: "Save",
					cancel: "Cancel"
				},
				components: {
					Login: {
						heading: "Login",
						title: "Welcome back",
						subTitle: "to Forever Loved",
						Form: {
							Email: {
								label: "Email",
								placeholder: "Enter your email"
							},
							Password: {
								label: "Password",
								placeholder: "Enter your password"
							}
						},
						Account: {
							copy: "I don't have an account.",
							createAccount: "Create an account.",
							forgotPassword: "I've forgotten my password."
						}
					},
					Home: {
						title: "Forever Loved",
						adminBadge: "Admin",
						editorBadge: "Editor",
						Memorials: {
							heading: "Your Memorials",
							addMemorial: "Create a Memorial",
							shareMemorial: "Share Page",
							roleOwner: "Creator",
							roleAdmin: "Admin",
							roleEditor: "Editor"
						},
						Communities: {
							heading: "Your Communities",
							addCommunities: "Discover Communities",
							shareCommunity: "Share",
							Carousel: {
								Slide1: {
									title: "Find Photos You've Never Seen",
									copy: "Join communities to find new photos, memories and stories from those who knew them.",
								},
								Slide2: {
									title: "Support meaningful causes",
									copy: "Have you lost someone close to you from a specific cause? Join a community to support this cause and donate to related charities.",
								},
								Slide3: {
									title: "Connect with others",
									copy: "Meet others who are going through similar situations in their own lives. Build your support network to deal with grief and so much more.",
								},
							}
						}
					},
					Header: {
						invite: "Invite Family",
						addCommunity: "Add {{name}} to a community"
					},
					TreeOfLife: {
						title: "Tree of Life",
						subTitle: "Donate leaves to grow {{name}}'s Tree.",
						leafDescription1: "{{currencySymbol}}{{amount}} donation from {{donor}} to ",
						leafDescription2: "{{recipient}}",
						amountFrom: "{{currencySymbol}}{{amount}} from",
						viewAll: "View All",
						donateALeaf: "Donate a Leaf"
					},
					Timeline: {
						memoriesTitle: "Memories",
						memoriesSubTitle: "Add to {{name}}’s Life Story and see Stories appear.",
						addMemory: "Create a Memory",
						addMemorySub: "(e.g. A family walk on Christmas day)",
						lifeEventTitle: "Life Story",
						lifeEventSubTitle: "Meaningful moments throughout their life.",
						addLifeEvent: "Add to {{name}}’s Life Story",
						addLifeEventSub: "(e.g. Their wedding day)",
						obituaryTitle: "Family Story",
						obituarySubTitle: "Capture the essence of the {{name}} family’s story through the years.",
						addObituary: "Write a family story",
						readObituary: "Read more",
						timelineBirth: "{{name}} was born",
						timelineDeath: "{{name}} passes away"
					},
					Messages: {
						title: "Messages",
						subTitle: "Notes from loved ones.",
						viewAll: "View All",
						writeMessage: "Write a Message"
					},
					SetupWidget: {
						title: "Complete {{name}}'s memorial",
						step01Symbol: "1",
						step01Description: "Add a memorial picture & cover photo",
						continue: "Continue"
					},
					Tombstone: {
						title: "Tombstone",
						subTitle: "Generated by Forever Loved",
						placeStone: "Place a Gift"
					}
				},
			},

			// FRENCH
			fr: {
				nativeName: "French",
				translation: {
					key: "Bonjour Éternellement Aimé.",
					anotherKey: {
						key: 'Une autre clé.',
						description: 'Une autre description de clé.'
					},
					error: {
						404: "Malheureusement, nous ne parvenons pas à trouver ce que vous recherchez :(",
						unknown: "Oops, cela n'était pas censé se produire."
					}
				},
				common: {
					save: "Enregistrer",
					cancel: "Annuler"
				},
				components: {
					Login: {
						heading: "Connexion",
						title: "Bon retour",
						subTitle: "sur Forever Loved",
						Form: {
							Email: {
								label: "Courriel",
								placeholder: "Entrez votre adresse e-mail"
							},
							Password: {
								label: "Mot de passe",
								placeholder: "Entrez votre mot de passed"
							}
						},
						Account: {
							copy: "Je n'ai pas de compte.",
							createAccount: "Créer un compte.",
							forgotPassword: "J'ai oublié mon mot de passe."
						}
					},
					Home: {
						title: "Maison",
						adminBadge: "Administrateur",
						editorBadge: "Éditeur",
						Memorials: {
							heading: "Vos mémoriaux",
							addMemorial: "Créer un Mémorial",
							shareMemorial: "Partager la page",
							roleOwner: "PROPRIÉTAIRE",
							roleAdmin: "ADMINISTRATEUR",
							roleEditor: "ÉDITEUR"							
						},
						Communities: {
							heading: "Vos Communautés",
							addCommunities: "Découvrir des Communautés",
							shareCommunity: "Partager",
							Carousel: {
								Slide1: {
									title: "Trouvez des photos que vous n'avez jamais vues",
									copy: "Rejoignez des communautés pour découvrir de nouvelles photos, souvenirs et histoires de ceux qui les ont connus.",
								},
								Slide2: {
									title: "Soutenir des causes importantes",
									copy: "Avez-vous perdu quelqu'un de proche à cause d'une cause spécifique ? Rejoignez une communauté pour soutenir cette cause et faire des dons à des organismes de bienfaisance liés.",
								},
								Slide3: {
									title: "Se connecter avec d'autres.",
								},
							}
						},
					},
					Header: {
						invite: "Inviter",
						addCommunity: "Ajouter {{name}} à une communauté"
					},
					TreeOfLife: {
						title: "Arbre de vie",
						subTitle: "Offrez des feuilles pour faire pousser l'arbre de {{name}}.",
						leafDescription1: "{{currencySymbol}}{{amount}} La donation de {{donor}} à ",
						leafDescription2: "{{recipient}}",
						amountFrom: "{{currencySymbol}}{{amount}} de",
						viewAll: "Voir tout",
						donateALeaf: "Donner une feuille"
					},
					Timeline: {
						memoriesTitle: "Souvenirs",
						memoriesSubTitle: "Ajoutez à l'histoire de vie de {{name}} et voyez les histoires apparaître.",
						addMemory: "Créer un souvenir",
						addMemorySub: "(ex. Une promenade en famille le jour de Noël)",
						lifeEventTitle: "Histoire de vie",
						lifeEventSubTitle: "Moments significatifs tout au long de leur vie.",
						addLifeEvent: "Ajouter à l'histoire de vie de {{name}}",
						addLifeEventSub: "(ex. Le jour de leur mariage)",
						obituaryTitle: "Avis de décès",
						obituarySubTitle: "Un avis de décès est un hommage écrit concis qui commémore la vie d'une personne et met en lumière ses réalisations significatives",
						addObituary: "Écrire un avis de décès",
						timelineBirth: "{{name}} est né",
						timelineDeath: "{{name}} décède"
					},
					Messages: {
						title: "Messages",
						subTitle: "Notes de proches.",
						viewAll: "Voir tout",
						writeMessage: "Écrire un message"
					},
					SetupWidget: {
						title: "Compléter le mémorial de {{name}}",
						step01Symbol: "1",
						step01Description: "Ajouter une photo commémorative et une photo de couverture",
						continue: "Continuer"
					},
					Tombstone: {
						title: "Pierre tombale",
						subTitle: "Généré par Forever Loved",
						placeStone: "Placer une pierre"
					}
				}
			},

			'en-US': {
				translation: {
					key: "Howdie Forever Loved."
				}
			},

			af: {
				nativeName: "Afrikaans",
				translation: {
					key: "Goeie dag Forever Loved.",
					anotherKey: {
						key: 'Ander sleutel.',
						description: 'Ander sleutel beskrywing.'
					},
					error: {
						404: "Ongelukkig kan ons nie kry waarna jy soek nie :(",
						unknown: "Oeps, dit was nie veronderstel om te gebeur nie."
					}
				},
				common: {
					save: "Stoor",
					cancel: "Kanselleer"
				}
			}
		}
	});

// i18next.
// Also Pluralization
// And Interpolation
// Value formatimg eg. Number() 1000 = 1,000.00 in en and 1.000,00 in de 
// Context - "I like to eat a cake" or given a different context "I really like a muffin".
// And all this can be combined.
// Help me please :/

// Tests

// console.log("i18n testing en : " + i18next.t("key", {lng: "en-US"}));
// console.log("i18n testing en : " + i18next.t("anotherKey.key", {lng: "en-US"}));
// console.log("i18n testing en : " + i18next.t("anotherKey.description", {lng: "en-US"}));
// console.log("i18n testing en : " + i18next.t("common:save", {lng: "en-US"}));
// console.log("i18n testing en : " + i18next.t("common:cancel", {lng: "en-US"}));

// console.log("i18n testing af : " + i18next.t("key", {lng: "af"}));
// console.log("i18n testing af : " + i18next.t("anotherKey.key", {lng: "af"}));
// console.log("i18n testing af : " + i18next.t("anotherKey.description", {lng: "af"}));
// console.log("i18n testing af : " + i18next.t("save", {lng: "af", ns: "common"}));
// console.log("i18n testing af : " + i18next.t("cancel", {lng: "af", ns: "common"}));

const code = 404;
const anotherCode = "unknown";

// console.log("i18n testing af error: " + i18next.t(`error.${code}`, {lng: "af"}));
// console.log("i18n testing en error: " + i18next.t(`error.${anotherCode}`, {lng: "en-US"}));