import React, { useContext, useEffect, useRef, useState } from "react";
import AvatarEditor from 'react-avatar-editor';
import imageCompression from "browser-image-compression";
import { AppContext } from "../../AppContext";
import { MemoryCreateType, PictureGalleryType, PictureUploadType } from "../AppType";
import { Modal } from "react-bootstrap";
import { ASSET_URL, fetchImage, fetchRecords, postFormData, postFormPictureFormData } from "../../utils/AppUtil";
import { useSpring } from "@react-spring/web";
import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react'
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";
import 'animate.css';

const useGesture = createUseGesture([dragAction, pinchAction]);

type ModalProps = {
    triggerMemory: (value: string) => void;
    handleEventCreated: (eventType: string, status: boolean, error: string) => void;
}

export const CreateMemory: React.FC<ModalProps> = ({triggerMemory, handleEventCreated}) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Cropping & zooming refs
    const pinchRef = useRef<HTMLDivElement>(null!)
    const editorRef = useRef<AvatarEditor>(null!);
    const pictureInputRef = useRef<HTMLInputElement>(null!);

    // Form progression
    const [formSuccess, setFormSuccess] = useState<string>("");
    const [formStep, setFormStep] = useState<number>(0);
    // 0 = Title
    // 1 = Message
    // 2 = Media options
    // 3 = Add media
    // 4 = Final screen

    // Gallary & Editor states
    const [formStateImages, setFormStateImages] = useState<string[]>([]);
    const [newImage, setNewImage] = useState<File>();
    const [newImages, setNewImages] = useState<File[]>([]);
    const [newImageArray, setNewImageArray] = useState<File[]>([]);
    
    const [zoom, setZoom] = useState<number>(1);
    const [addPhotos, setAddPhotos] = useState<boolean>(false);
    const [imageGallery, setImageGallery] = useState<React.ReactElement[]>();
    const [finalEdit, setFinalEdit] = useState<boolean>(false);
    const [finalImageArray, setFinalImageArray] = useState<string[]>([]);
    const [finalImages, setFinalImages] = useState<React.ReactElement[]>();

    // Modal states
    const [animation, setAnimation] = useState<string>("animate__animated animate__flipInY");
    const [charactersRemaining, setCharactersRemaining] = useState<number>(240);
    const [charactersRemainingText, setCharactersRemainingText] = useState<string>(" characters max");
    const [btnNext, setBtnNext] = useState<boolean>(true);
    const [btnNextAnimation, setBtnNextAnimation] = useState<string>("");
    const [btnSkip, setBtnSkip] = useState<boolean>(false);
    const [btnSkipAnimation, setBtnSkipAnimation] = useState<string>("");
    const [spinnerLoading, setspinnerLoading] = useState<boolean>(false);
    const [formProgressHide, setFormProgressionHide] = useState<string>("");

    // formState for loading image to gallery
    const initForm: PictureUploadType = {
        memorialId: memorialData.memorialId,
        file: null
    }
    const [formState, setFormState] = useState<PictureUploadType>(initForm);

    // formState for the memory being created
    const initFormMemory: MemoryCreateType = {
        title: "",
        message: "",
        pictureIds: [],
        extra: {
            additionalProp1: {},
            additionalProp2: {},
            additionalProp3: {}
        }
    }
    const [formStateMemory, setFormStateMemory] = useState<MemoryCreateType>(initFormMemory);

    // Initial settings for touch-zoom functionality
    const [style, api] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: zoom,
        rotateZ: 0,
    }));

    // useEffect to prevent default event behaviour for touch actions on component mount
    useEffect(() => {
        const handler = (e: Event) => e.preventDefault();
        document.addEventListener('gesturestart', handler);
        document.addEventListener('gesturechange', handler);
        document.addEventListener('gestureend', handler);

        // return runs cleanup on unmount to remove event listeners
        return () => {
            document.removeEventListener('gesturestart', handler);
            document.removeEventListener('gesturechange', handler);
            document.removeEventListener('gestureend', handler);
        };
    }, []);

    // Select image from 'local' gallery
    // const selectImageFromFile = (file: File) => {
    //     setNewImage(file);
    // }

    // Build image array
    const buildImageArray = (): React.ReactElement[] => {
        return newImageArray.map((file, index) => {
            const imageSrc = URL.createObjectURL(file);

            return <div key={`new-${index}`} className={`gallery-image-wrapper ${file === newImage ? 'selected' : ''}`}>
                       <img src="/images/fl-icon-bin.svg" className="gallery-image-remove" onClick={() => handleImageRemove(file)} />
                       <img src={imageSrc} style={{borderRadius: "8px"}} width={124} height={124} onClick={() => handleImageSelect(file)} />;
                   </div>
        });
    }

    const handleImageRemove = (file: File) => {
        const itemIndex = newImageArray.indexOf(file);

        if (itemIndex > -1) {

            // Create a new array with the item removed
            const updatedArray = [...newImageArray];
            updatedArray.splice(itemIndex, 1);

            // Update the state with the new array
            setNewImageArray(updatedArray);

            if (itemIndex === 0) {
                if (newImageArray.length === 1) {
                    setNewImage(undefined);
                } else {
                    setNewImage(newImageArray[itemIndex]);
                }
            } else {
                setNewImage(newImageArray[itemIndex - 1]);
            }
        }
    }

    const handleImageSelect = (file: File) => {
        setNewImage(file);
    }

    // Scroll zoom for pc users
    const scaleStep = 0.1;
    document.getElementById('selectedImageContainer')?.addEventListener('wheel', (event) => {
        event.preventDefault();
        
        if (event.deltaY < 0) {
            setZoom(zoom + scaleStep);
        } else {
            setZoom(zoom - scaleStep);
        };
    });

    // Deactivate DOM zooming to allow uninterupted image zooming
    const deactivateZoomDiv = document.getElementById('deactivateZoom');
    deactivateZoomDiv?.addEventListener('touchmove', function(event) {
        const touchEvent = event as TouchEvent & { scale: number };
        if (touchEvent.scale !== 1) {
            event.preventDefault();
        }
    }, {passive: false});

    // Touch-pinch-zoom actions
    useGesture(
        {
            onDrag: ({pinching, cancel, offset: [x, y], ...rest}) => {
                if (pinching) return cancel();
                api.start({ x, y });
            },
            onPinch: ({offset: [s], first}) => {
                if (first) {
                    const {width, height, x, y} = pinchRef.current!.getBoundingClientRect();
                    const tx = x + width / 2;
                    const ty = y + height / 2;
                    api.start({ x: -tx, y: -ty });
                }
                setZoom(s);
            },
        },
        {
            target: pinchRef,
            drag: {from: () => [style.x.get(), style.y.get()]},
            pinch: {scaleBounds: {min: 0.25, max: 5}, rubberband: false},
        }
    );

    // Form change
    const handleFormChange = (e: React.ChangeEvent<any>) => {

        if (e.target.name === "message") {
            const value = e.target.value;

            if (value.length <= 240) {
                setCharactersRemaining(240 - value.length);

                if (value.length < 240) {
                    setCharactersRemainingText(" characters remaining");
                } else {
                    setCharactersRemainingText(" characters max");
                }

                setFormStateMemory(() => ({
                    ...formStateMemory,
                    [e.target.name]: e.target.value
                }));
            }
        }

        if (e.target.name === "title") {
            setFormStateMemory(() => ({
                ...formStateMemory,
                [e.target.name]: e.target.value
            }));
        }

        if (e.target.type === "file" && e.target.files != null) {
            setNewImage(e.target.files[0]);

            setNewImageArray(newImageArray => [...newImageArray, e.target.files[0]] );
            // setImageSelected(false);
        }
    }

    // Navigate form forwards
    const handleClickNext = () => {
        if (formStep === 1) {
            setBtnNextAnimation('animate__animated animate__fadeOutDown animate__faster');
            setTimeout(() => {
                setBtnNext(false);
                setBtnSkip(true);
                setBtnSkipAnimation('animate__animated animate__fadeInUp animate__faster');
            }, 500);
        }

        if (formStep === 3) {
            setFormStateMemory((formStateMemory) => ({
                ...formStateMemory,
                pictureIds: formStateImages
            }));
        }
        setFormStep(formStep + 1);
    }

    // Navigate form backwards
    const handleClickBack = () => {
        if (formStep === 2) {
            setBtnSkipAnimation('animate__animated animate__fadeOutDown animate__faster');
            setTimeout(() => {
                setBtnSkip(false);
                setBtnNext(true);
                setBtnNextAnimation('animate__animated animate__fadeInUp animate__faster');
            }, 500);
            setFormStep(formStep - 1);

        } else if (formStep === 3) {
            setBtnSkipAnimation('animate__animated animate__fadeInUp animate__faster');
            setBtnSkip(true);
            setFormProgressionHide("");
            setFormStep(formStep - 1);

        } else if (formStep === 4) {
            setBtnNextAnimation('animate__animated animate__fadeOutDown animate__faster');
            setAddPhotos(false);
            setFormProgressionHide("");

            setTimeout(() => {
                setBtnSkipAnimation('animate__animated animate__fadeInUp animate__faster');
                setBtnNext(false);
                setBtnSkip(true);
            }, 500);
            setFormStep(2);
        } else {
            setFormStep(formStep - 1);
        }
    }

    // Skip adding media - go to final screen
    const handleSkip = () => {
        setAddPhotos(false);
        setBtnSkipAnimation('animate__animated animate__fadeOutDown animate__faster');
        setTimeout(() => {
            setFormProgressionHide("animate__animated animate__fadeOutLeft animate__faster");
            setBtnSkip(false);
            setFormStep(4);
        }, 250);
    }

    // Closing modal or clicking back from formStep = 0
    const handleClickBackClose = () => {
        setAnimation("animate__animated animate__flipOutY");
        triggerMemory("animate__animated animate__flipInY");
    }

    const handleAddImage = () => {
        setAddPhotos(true);

        if (formStep === 4) {
            setFormStep(formStep - 1);
        }
        
        pictureInputRef.current.click();
    }

    // Add images media option selected
    const handleAddPhotos = () => {
        setAddPhotos(true);
        setFormProgressionHide("animate__animated animate__fadeOutLeft animate__faster");
        setFormStep(3);

        setBtnSkipAnimation('animate__animated animate__fadeOutDown animate__faster');
        setTimeout(() => {
            setBtnSkip(false);
        }, 500);
    }
    
    // TODO - Scan photos media option selected
    const handleScanPhotos = () => {

    }
    
    // TODO - Add videos media option selected
    const handleAddVideos = () => {

    }

    // Add selected images to the formState
    const handleAddImageArray = () => {
        setFormStateMemory((prevState) => ({
            ...prevState,
            pictureIds: formStateImages
        }));

        setFinalImageArray(formStateImages);
        // setFinalImages(buildImageArray);

        setFormStep(formStep + 1);
    }

    // Handle image cropping
    const handleCrop = async (): Promise<Blob> => {
        const canvasImage: HTMLCanvasElement = editorRef.current.getImage();
    
        return new Promise((resolve, reject) => {
            canvasImage.toBlob((blob: any) => {
                if (!blob) {
                    reject(new Error("Failed to convert canvas to Blob"));
                    return;
                }
    
                let name = "";
                if (pictureInputRef?.current?.files?.length) {
                    name = pictureInputRef.current.files[0].name;
                }
    
                setFormState((formState): PictureUploadType => {
                    const form = { ...formState, file: blob };
                    return form;
                });
    
                resolve(blob);
            });
        });
    }

    // Handle image compression
    const handleCompression = async (file: any): Promise<Blob> => {
        console.log('originalFile instanceof Blob', file instanceof Blob); // true
        console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
    
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };
    
        try {
            const compressedFile = await imageCompression(file, options);
            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    
            return compressedFile;
        } catch (error: any) {
            console.log(error.message);
            throw error;
        }
    }

    // Truncate title
    const truncate = (text: string) => {
        return text.length > 30 ? text.substring(0, 30) + "..." : text;
    }

    // Post image data to gallery
    const handlePostPicture = async (compressedFile: Blob, fileName: string) => {
        console.log('Posting Form State:', formState);
        console.log('Posting File:', compressedFile);
    
        const formData = new FormData();
        formData.set("memorialId", formState.memorialId);
        formData.set("file", compressedFile);
    
        postFormPictureFormData(
            commonData.token,
            'picture',
            formData,
            (responseData: any) => {
                console.log("Multi-post Success", responseData);
                setNewImages((prevNewImages) => prevNewImages.filter((file) => file.name !== fileName));
            },
            (response: any) => { // error
                console.log("Multi-post Fail", response);
            }
        );
    }

    // Handle click to crop, compress then post images to gallery
    const handleClick = async (): Promise<void> => {
        try {
            setspinnerLoading(true);
            const croppedBlob = await handleCrop();
            console.log('Cropping completed successfully.');
            console.log('CROPPED:', croppedBlob);
    
            const compressedBlob = await handleCompression(croppedBlob);
            console.log('Compression completed successfully.');
            console.log('COMPRESSED:', compressedBlob);
    
            // Directly call handlePost and pass the compressed file and its name
            await handlePostPicture(compressedBlob, newImage!.name);
    

            newImages.shift()
            // selectNextImage();

            setspinnerLoading(false);
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    // Activate final screen input fields
    const handleFinalEdit = () => {
        setFinalEdit(c => !c);
    }

    // Post form data to create memory
    const handlePost = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();

        postFormData(
            commonData.token,
            `memorial/${memorialData.memorialId}/memory`,
            formStateMemory,
            (response: any) => {
                handleEventCreated('Memory', true, '');
                setFormSuccess("");
                setFormStep(5);
            },
            (error: any) => { // error
                handleEventCreated('Memory', false, error);
                setFormSuccess(error);
                setFormStep(5);
            }
        );
    }

    return (
        <>
            <input ref={pictureInputRef} id="file" name="file" type="file" accept="image/*" onChange={handleFormChange} style={{display: "none"}} />
            
            <div className={`form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep < 0 ? 'prev' : 'next')} h-100 ${animation}`} style={{backgroundColor: "#00222F", zIndex: "999"}}>

                <div className="d-flex justify-content-center p-3">
                    {formStep !== 5 &&
                        <>
                            {formStep === 0 ?
                                <img src="/images/fl-arrow-back.svg" onClick={handleClickBackClose} className="form-progress-back"/>
                                :
                                <img src="/images/fl-arrow-back.svg" onClick={handleClickBack} className="form-progress-back"/>
                            }
                        </>
                    }
                    <p className="modal-title">New Memory</p>
                    <button className="btn btn-modal-header" onClick={handleClickBackClose} style={{zIndex: 999}} />
                </div>
                
                <div className={`form-progress form-progress-absolute ${formProgressHide}`} style={{top: "100px"}}>
                    <img src={`/images/fl-progress-camera.svg`} className={`form-progress-current form-progress-${formStep}`} />
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                </div>


                {/* Step 0 - Title */}
                <div className={`form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep < 0 ? 'prev' : 'next')}`} style={{padding: "170px 16px 16px"}}>
                    <p className="modal-heading mb-4">What’s this memory called?</p>

                    <div className="login-form">
                        <div className="mb-4">
                            <label htmlFor="title" className="form-label">Title</label>
                            <input id="title" name="title" type="text" onChange={handleFormChange} className="form-control" placeholder="Enter a short title for this event" />
                        </div>
                    </div>
                </div>

                {/* Step 1 - Message */}
                <div className={`form-screen form-content form-content-${formStep === 1 ? 'current' : (formStep < 1 ? 'prev' : 'next')}`} style={{padding: "170px 16px 16px"}}>
                    <div className="form-floating">
                        <textarea id="message" name="message" value={formStateMemory.message} onChange={handleFormChange} maxLength={240} className="form-control memory-message-input pt-0" placeholder="Write a description of the memory" />
                        <p className="memory-message-limit">{charactersRemaining} {charactersRemainingText}</p>
                    </div>
                </div>

                {/* Step 2 - Media selection */}
                <div className={`form-screen form-content form-content-${formStep === 2 ? 'current' : (formStep < 2 ? 'prev' : 'next')}`} style={{padding: "170px 16px 16px"}}>
                    <p className="modal-heading mb-4">Upload some photos or videos</p>

                    <div className="d-flex flex-column">
                        <div className="image-upload-menu-item" onClick={handleAddPhotos}>
                            <div className="d-flex gap-3">
                                <img src="images/camera-icon.svg" width={24} />
                                <p className="event-add-assets">Add photo/s</p>
                            </div>
                        </div>

                        <div className="image-upload-menu-item" onClick={handleScanPhotos}>
                            <div className="d-flex gap-3">
                                <img src="images/scan-icon.svg" width={24} />
                                <p className="event-add-assets">Scan a photo</p>
                            </div>
                        </div>

                        <div className="image-upload-menu-item" onClick={handleAddVideos}>
                            <div className="d-flex gap-3">
                                <img src="images/recorder-icon.svg" width={24} />
                                <p className="event-add-assets">Add Video/s</p>
                            </div>
                            <div className="premium-badge">Premium</div>
                        </div>
                        
                    </div>
                </div>

                {/* Step 3 - Add media (images) */}
                <div className={`form-screen form-content form-content-${formStep === 3 ? 'current' : (formStep < 3 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>

                    {addPhotos === true &&
                        <>
                            {newImage ?
                                <>
                                    <div className="event-photo-uploading">
                                        <div ref={pinchRef} id="deactivateZoom" style={{height: "100vw"}}>
                                            <div className="d-flex justify-content-center">
                                                <div className="image-canvas-grid" />

                                                {spinnerLoading &&
                                                    <div className="image-canvas-loading animate__animated animate__fadeIn animate__faster">
                                                        <img src="images/creating-memorial.svg" />
                                                        <p className="loading-ellipsis">Uploading Image</p>
                                                    </div>
                                                }

                                                <div className="memorial-img-crop-wrapper d-flex justify-content-center align-items-center">
                                                    <div id="selectedImageContainer" className="memorial-img-crop-preview">
                                                        {newImage &&
                                                            <AvatarEditor
                                                                ref={editorRef}
                                                                image={newImage}
                                                                border={0}
                                                                borderRadius={0}
                                                                scale={zoom}
                                                                rotate={0}
                                                                style={{
                                                                    transform: `translate(${style.x}px, ${style.y}px) scale(${style.scale}) rotate(${style.rotateZ}deg)`,
                                                                    touchAction: "pinch-zoom",
                                                                    width: "100vw",
                                                                    height: "100vw"
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex flex-column w-100">
                                            <div className="d-flex justify-content-center gap-2 p-3">
                                                <img src="/images/pinch-zoom.svg" />
                                                <p className="pich-zoom-text">Pinch & zoom to crop</p>
                                            </div>
                                    
                                            <div className="image-gallery d-flex gap-4">
                                                {buildImageArray()}
                                                <div className="event-additional-images" onClick={handleAddImage} style={{cursor: 'pointer'}}><img src="/images/fl-plus-lg.svg" /></div>
                                            </div>
                                    
                                        </div>
                                
                                        <button className="btn fl-btn-modal-bottom button-absolute" onClick={handleClickNext} type="button">Done</button>

                                    </div>
                                </>
                                :
                                <>
                                    <div className="d-flex justify-content-center align-items-center h-100">
    
                                        <div className="add-photo-btn" onClick={handleAddImage}>
                                            <img src="/images/fl-plus-lg.svg" width={32} height={32} />
                                            <p>Click to add an image</p>
                                        </div>
    
                                    </div>
                                </>
                            }
                        </>
                    }

                    {formStateImages && formStateImages.length !== 0 &&
                        <div className="fl-btn-group-modal-bottom">
                            <div className="image-gallery-fade animate__animated animate__slideInUp animate__faster"></div>
                            <button className="btn fl-btn-modal-bottom animate__animated animate__slideInUp animate__faster" onClick={handleAddImageArray} type="button">Add</button>
                        </div>
                    }
                </div>

                {/* Step 4 - Final edit */}
                <div className={`form-screen form-content form-content-${formStep === 4 ? 'current' : (formStep < 4 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>
                    <div className="d-flex justify-content-between gap-3">
                        {finalEdit ?
                            <div className="login-form w-100">
                                <input
                                    id="title"
                                    name="title"
                                    type="text"
                                    onChange={handleFormChange}
                                    className="form-control"
                                    value={formStateMemory.title}
                                    placeholder="Enter a short title for this event"
                                    style={{height: '76px', fontSize: '1.5rem'}} />
                            </div>:
                            <p className="modal-heading w-100">{truncate(formStateMemory.title)}</p>
                        }
                        <img src="/images/fl-btn-pen.svg" onClick={handleFinalEdit} width={32} height={32} style={{cursor: 'pointer'}} />
                    </div>

                    <div className="event-images-preview no-scrollbar gap-4">
                        {buildImageArray()}
                        {/* {finalImages} */}
                        <div className="event-additional-images" onClick={handleAddImage} style={{cursor: 'pointer'}}><img src="/images/fl-plus-lg.svg" /></div>
                    </div>

                    {finalEdit ?
                        <div className="form-floating">
                            <textarea
                                id="message"
                                name="message"
                                value={formStateMemory.message}
                                onChange={handleFormChange}
                                maxLength={240}
                                className="form-control memory-message-input final-edit pt-0"
                                placeholder="Write a description of the memory"
                                style={{backgroundColor: 'rgba(255, 255, 255, 0.0509803922)', border: '2px solid #40626F'}}
                            />
                            <p className="memory-message-limit">{charactersRemaining} {charactersRemainingText}</p>
                        </div>:
                        <p className="memory-preview-message">{formStateMemory.message}</p>
                    }
                    
                    <button className={`btn fl-btn-modal-bottom`} onClick={handlePost} style={{position: "absolute", bottom: 16, width: "calc(100% - 32px)", zIndex: "999"}}>Save Memory</button>
                </div>

                <div className={`form-screen form-content form-content-${formStep === 5 ? 'current' : (formStep < 5 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>

                    <div className="d-flex flex-column h-100">
                        <div className="d-flex flex-column gap-2 align-items-center justify-content-center h-100">
                            <img src="/images/fl-login-tick.svg" />
                            <div className="login-success-txt d-flex flex-column gap-1">
                                <p>{formSuccess === "" ? 'Memory added' : 'Failed'}</p>
                                <p>{formSuccess}</p>
                            </div>
                        </div>

                        <button onClick={handleClickBackClose} className="btn fl-btn-modal-bottom">OK</button>
                    </div>

                </div>

                {btnNext &&
                    <div className="fl-btn-group-modal-bottom">
                        <button className={`btn fl-btn-modal-bottom ${btnNextAnimation}`} onClick={handleClickNext}>Next</button>
                    </div>
                }
                {btnSkip &&
                    <>
                        <button className={`btn-modal-footer-skip ${btnSkipAnimation}`} onClick={handleSkip}>Skip</button>
                    </>
                }
            </div>            
        </>
    );
}
