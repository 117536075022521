import React, { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import { Modal } from "react-bootstrap";
import { ModalProfilePicture } from "./ModalProfilePicture";
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";

export const ModalMemorialImages: React.FC = () => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;
    
    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [show, setShow] = useState(false);

    const handleShow = () => {
        setModalAnimation('modal-image-drawer memorial-images modal-account-drawer-background animate__animated animate__slideInUp');
        setShow(true);
    }

    const handleClose = () => {
        setModalAnimation('modal-image-drawer modal-account-drawer-background animate__animated animate__slideOutDown');
        setTimeout(() => {
            setShow(false);
        }, 300);
    }

    const handleModalClose = () => {
        setTimeout(() => {
            handleClose();
        }, 500);
    }

    return (
        <>
            <img src={memorialData.memorialPictureId ? '/images/fl-btn-pen.svg' : '/images/fl-btn-profile-add.svg'} className="profile-add btn-profile-add" onClick={handleShow} style={{cursor: "pointer"}} />

            <Modal className={modalAnimation} show={show} onHide={handleClose}>
                <Modal.Header className="pb-1" onClick={handleClose}>
                    <div className="modal-account-drawer-close"></div>
                </Modal.Header>
                
                <Modal.Body className="d-flex flex-column gap-2">
                    <div className="memorial-images-txt d-flex flex-column gap-1">
                        <p>Memorial Imagery</p>
                        <p>Add some images to {memorialData.firstName}’s memorial.</p>
                    </div>
                    <div className="d-flex flex-column pt-3">
                        <ModalProfilePicture endpoint={"picture"} modalClose={handleModalClose} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}





