import React, { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import { Modal } from "react-bootstrap";
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";

interface ModalProps {
    onButtonClick: () => void;
}

export const ModalRememberDate: React.FC<ModalProps> = ({onButtonClick}) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;
    
    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [show, setShow] = useState(false);

    const pageWrapper = document.getElementById('pageWrapper');
    

    const handleShow = () => {

        if (pageWrapper) {
            pageWrapper.style.backdropFilter = 'blur(3px)';
        }

        setModalAnimation('modal-remember-date modal-account-drawer-background animate__animated animate__slideInUp');
        setShow(true);
    }

    const handleClose = () => {
        setModalAnimation('modal-remember-date modal-account-drawer-background animate__animated animate__slideOutDown');
        setTimeout(() => {
            if (pageWrapper) {
                pageWrapper.style.backdropFilter = '';
            }
            setShow(false);
        }, 300);
    }

    const handleClick = () => {
        handleClose();
        onButtonClick();
    }

    return (
        <>
            <p className="fl-txt-remember-date mt-4 mb-3" onClick={handleShow}>I don’t remember the exact date</p>

            <Modal className={modalAnimation} show={show} onHide={handleClose}>
                <Modal.Header className="" onClick={handleClose}>
                    <div className="modal-account-drawer-close"></div>
                </Modal.Header>
                
                <Modal.Body className="d-flex flex-column justify-content-between">

                        <div className="modal-drawer-txt d-flex flex-column gap-2">
                            <p>Why not create a memory?</p>
                            <p>Life events need a date. Memories don’t.</p>
                        </div>

                        <button className="btn btn-modal-footer" onClick={handleClick}>Create Now</button>

                </Modal.Body>
            </Modal>
        </>
    );
}





