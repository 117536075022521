import React from "react";
import { Link } from "react-router-dom";

export const Page404: React.FC = () => {

    return (
        <div className="d-flex justify-content-center align-items-center" style={{height: "100dvh"}}>
            <img src="/images/splash-error.png" className="splash-error-img" />
            <div className="splash-error d-flex flex-column justify-content-center align-items-center gap-1">
                <p>Sorry</p>
                <p>Something went wrong</p>
            </div>
            <Link to="/" className="btn fl-btn-modal-bottom">Go back home</Link>
        </div>
    );
} 