import React, { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import { Modal } from "react-bootstrap";
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";

interface ModalProps {
    setCustomAmount: (newNumber: number) => void;
}


export const ModalSetLeafAmount: React.FC<ModalProps> = ({setCustomAmount}) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;
    
    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [show, setShow] = useState(false);

    const [currency, setCurrency] = useState<string>('$');
    const [amount, setAmount] = useState<number>(60);

    const pageWrapper = document.getElementById('pageWrapper');
    
    const handleShow = () => {

        if (pageWrapper) {
            pageWrapper.style.backdropFilter = 'blur(3px)';
        }

        setModalAnimation('modal-remember-date donate-leaf modal-account-drawer-background animate__animated animate__slideInUp');
        setShow(true);
    }

    const handleClose = () => {
        setModalAnimation('modal-remember-date donate-leaf modal-account-drawer-background animate__animated animate__slideOutDown');
        setTimeout(() => {
            if (pageWrapper) {
                pageWrapper.style.backdropFilter = '';
            }
            setShow(false);
        }, 300);
    }

    
    const handleAmountInc = () => {
        // if (amount < 50) {
            setAmount(c => (c + 5));
        // }
    }
            
    const handleAmountDec = () => {
        if (amount > 60) {
            setAmount(c => (c - 5));
            }
        }

    const handleClick = () => {
        setCustomAmount(amount);
        handleClose();
    }

    return (
        <>
            <button className="btn btn-modal-footer" onClick={handleShow}>Continue</button>

            <Modal className={modalAnimation} show={show} onHide={handleClose}>
                <Modal.Header className="" onClick={handleClose}>
                    <div className="modal-account-drawer-close"></div>
                </Modal.Header>
                
                <Modal.Body className="d-flex flex-column justify-content-between align-items-center gap-4 px-4">

                    <div className="modal-drawer-txt d-flex flex-column gap-2">
                        <p>Select an amount</p>
                        <p>A scarlet leaf goes a long way towards your chosen charity.</p>
                    </div>

                    <div className="leaf-tip-amount" style={{width: '75%'}}>
                        <img src="/images/icon-circle-minus.svg" onClick={handleAmountDec} width={24} height={24} alt="" />
                        <p>{currency}{amount}.00</p>
                        <img src="/images/icon-circle-plus.svg" onClick={handleAmountInc} width={24} height={24} alt="" />
                    </div>

                    <button className="btn btn-modal-footer" onClick={handleClick}>Continue</button>

                </Modal.Body>
            </Modal>
        </>
    );
}





