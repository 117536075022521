import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { AppProvider } from './AppContext';
import { MemorialProvider } from './MemorialContext';

const root = ReactDOM.createRoot(
  	document.getElementById('root') as HTMLElement
);
root.render(
   	<React.StrictMode>
		<AppProvider>
			<MemorialProvider>
				<App />
			</MemorialProvider>
		</AppProvider>
  	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
