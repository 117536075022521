import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { UserType } from "../AppType";
import { postFormData } from "../../utils/AppUtil";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";

type ModalProps = {
    loggedOutState: boolean;
}

export const ModalCreateAccount: React.FC<ModalProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [formStep, setFormStep] = useState<number>(0);
    const [show, setShow] = useState(false);
    const [modalAnimation, setModalAnimation] = useState<string>("modal-deactivate-scroll");
    const [formProgressHide, setFormProgressionHide] = useState<string>("")

    const refEmail = useRef<HTMLInputElement>(null!);
    const refFirstName = useRef<HTMLInputElement>(null!);

    const refBirthDateDay = useRef<HTMLInputElement>(null!);
    const refBirthDateMonth = useRef<HTMLInputElement>(null!);
    const refBirthDateYear = useRef<HTMLInputElement>(null!);

    const [invalidBirthDate, setInvalidBirthDate] = useState<boolean>(false);
    const [invalidBirthDay, setInvalidBirthDay] = useState<boolean>(false);
    const [invalidBirthMonth, setInvalidBirthMonth] = useState<boolean>(false);
    const [invalidBirthYear, setInvalidBirthYear] = useState<boolean>(false);

    const invalidBorder: string = '3px solid #DC3545';

    const formData: UserType = {
        firstname: "",
        lastname: "",
        mobileNumber: "",
        email: "",
        password: "",
        dateOfBirth: ""
    }

    useEffect(() => {
        // console.log(props.loggedOutState);
    }, [props.loggedOutState])

    const [formState, setFormState] = useState<UserType>(formData);
    
    const handleFormChange = (e: React.ChangeEvent<any>) => {
        if (e.target.type === "text" || e.target.type === "email" || e.target.type === "password") {
            setFormState((formState) => ({
                ...formState, 
                [e.target.id]: e.target.value
            }));
        }
    }

    // Validation (day)
    const validateDay = (value: string): string => {
        let numberValue: number = parseInt(value, 10);
    
        if (isNaN(numberValue) || /\D/.test(value)) {
            if (formStep === 1) {
                setInvalidBirthDay(true);
            }

            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            if (formStep === 1) {
                setInvalidBirthDay(false);
            }

            return '0' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            if (formStep === 1) {
                setInvalidBirthDay(false);
            }

            return numberValue.toString();

        } else {
            if (formStep === 1) {
                setInvalidBirthDay(true);
            }

            return 'invalid';
        }
    }

    // Validation (month)
    const validateMonth = (value: string): string => {
        let numberValue: number = parseInt(value, 10);
    
        if (isNaN(numberValue) || /\D/.test(value)) {
            if (formStep === 1) {
                setInvalidBirthMonth(true);
            }
            
            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            if (formStep === 1) {
                setInvalidBirthMonth(false);
            }

            return '0' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            if (formStep === 1) {
                setInvalidBirthMonth(false);
            }

            return numberValue.toString();

        } else {
            if (formStep === 1) {
                setInvalidBirthMonth(true);
            }

            return 'invalid';
        }
    }

    // Validation (year)
    const validateYear = (value: string): string => {

        let numberValue: number = parseInt(value, 10);
    
        if (isNaN(numberValue) || /\D/.test(value)) {
            if (formStep === 1) {
                setInvalidBirthYear(true);
            }
            
            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            if (formStep === 1) {
                setInvalidBirthYear(false);
            }

            return '000' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            if (formStep === 1) {
                setInvalidBirthYear(false);
            } 

            return '00' + numberValue.toString();

        } else if (numberValue >= 100 && numberValue < 1000) {
            if (formStep === 1) {
                setInvalidBirthYear(false);
            }

            return '0' + numberValue.toString();

        } else if (numberValue >= 1000 && numberValue < 10000) {
            if (formStep === 1) {
                setInvalidBirthYear(false);
            }

            return numberValue.toString();

        } else {
            if (formStep === 1) {
                setInvalidBirthYear(true);
            }

            return 'invalid';
        }
    }

    // Form change (date fields)
    const handleFormChangeDate = (e: React.ChangeEvent<any>) => {

        if (e.target.type === "text") {

            // - - - - - Birth Date - - - - -
            if (e.target.id === "birthDateDay") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: validateDay(e.target.value)
                }));

                if (e.target.value.length === 2) {
                    refBirthDateMonth.current.focus();
                }
            }

            if (e.target.id === "birthDateMonth") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: validateMonth(e.target.value)
                }));

                if (e.target.value.length === 2) {
                    refBirthDateYear.current.focus();
                }
            }

            if (e.target.id === "birthDateYear") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: validateYear(e.target.value)
                }));
            }
        }
    }

    const handlePost = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();

        postFormData(
            commonData.token,
            'user',
            formState,
            (response: any) => {
                setFormState(formData);
            },
            (error: any) => { // error
            }
        );

        handleClickNext();
    }

    const passwordCreateGroup = document.getElementsByClassName('form-password-create');
    const passwordCreate = passwordCreateGroup[0] as HTMLInputElement;

    const handlePasswordCreateShow = () => {
        if (passwordCreate.type === "password") {
            passwordCreate.type = "text";
        } else {
            passwordCreate.type = "password";
        }
    }

    let conditionalStyle: React.CSSProperties = {}
    if (formStep === 2) {
        conditionalStyle = {
            backgroundColor: "#00222f",
            height: "calc(100dvh - (256px))"
        }
    }

    // - - - - - Change Form Step - - - - -
    const handleClickNext = () => {
        if (formStep === 0) {
            setTimeout(() => {
                refFirstName.current.focus();
            }, 500);
        }

        if (formStep === 1) {
            setFormProgressionHide("animate__animated animate__fadeOutLeft animate__faster");    
            setTimeout(() => {
                setFormProgressionHide("");
            }, 1000);
        }
        
        setFormStep(formStep + 1);
    }

    const handleClickBack = () => {
        setFormStep(formStep - 1);

        if (formStep === 1) {
            setTimeout(() => {
                refEmail.current.focus();
            }, 500);
        }
    }

    const handleClickLogin = () => {
        // document.getElementById('buttonLogin')?.click();
        handleClose();
        setFormStep(0);
    }

    const handleShow = () => {
        // setModalAnimation('modal-account-drawer modal-account-drawer-background animate__animated animate__slideInUp');
        setShow(true);
    }

    const handleClose = () => {
        // setModalAnimation('modal-account-drawer modal-account-drawer-background animate__animated animate__slideOutDown');{
        setShow(false);
        setTimeout(() => {
            setFormStep(0);
        }, 1000)

    }

    return (
        <>
            {props.loggedOutState ?
                <button className="btn fl-btn-large mt-3" onClick={handleShow}>Create account</button> :
                <span onClick={handleShow} style={{whiteSpace: "nowrap", textDecoration: "underline", cursor: "pointer"}}>{ t("Login.Account.createAccount", {ns: "components"}) }</span>
            }

            <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Header>
                    {formStep != 0 && <img src="/images/fl-arrow-back.svg" onClick={handleClickBack} className="form-progress-back"/>}
                    <p className="modal-title">Create your account</p>
                    <button className="btn btn-modal-header" onClick={handleClose} />
                </Modal.Header>

                <Modal.Body>
                    <div className={`form-progress form-progress-absolute ${formProgressHide}`}>
                        <img src={`/images/fl-progress-heart.svg`} className={`form-progress-current form-progress-${formStep}`} />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                    </div>

                    <div className="form-container">
                        <div className={`form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep < 0 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>
                            <div className="d-flex flex-column gap-2">

                                <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                    <p>Join Forever Loved</p>
                                    <p>The final resting place online.</p>
                                </div>
                                
                                <div className="login-form">
                                    <div className="mb-4">
                                        <label htmlFor="email" className="form-label">{ t("Login.Form.Email.label", {ns: "components"}) }</label>
                                        <input ref={refEmail} id="email" name="email" type="email" onChange={handleFormChange} className="form-control" placeholder="Enter your own email address" required autoFocus />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="email" className="form-label">Mobile</label>
                                        <input id="mobileNumber" name="mobileNumber" type="text" onChange={handleFormChange} className="form-control" placeholder="1234 567 890" required />
                                    </div>
                                    <div className="mb-2" style={{position: "relative"}}>
                                        <label htmlFor="password" className="form-label">{ t("Login.Form.Password.label", {ns: "components"}) }</label>
                                        <input id="password" name="password" type="password" onChange={handleFormChange} className="form-control form-password-create" placeholder="Enter a strong password" required />
                                        <img src="/images/fl-eye.svg" onClick={handlePasswordCreateShow} style={{position: "absolute", top: "63%", right: "16px", cursor: "pointer"}}/>
                                    </div>
                                    <p className="input-subtext">Must contain: 1 Upper Case Letter, 1 Number</p>
                                </div>
                            </div>
                        </div>

                        <div className={`form-screen form-content form-content-${formStep === 1 ? 'current' : (formStep < 1 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>
                            <div className="d-flex flex-column gap-2">

                                <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                    <p>A few last things</p>
                                    <p>Let’s get to know each other a little better.</p>
                                </div>
                                
                                <div className="login-form">
                                    <div className="mb-4">
                                        <label htmlFor="firstname" className="form-label">First Name</label>
                                        <input ref={refFirstName} id="firstname" name="firstname" type="text"onChange={handleFormChange} className="form-control" placeholder="Enter your own first name" required />
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="lastname" className="form-label">Last Name</label>
                                        <input id="lastname" name="lastname" type="text" onChange={handleFormChange} className="form-control" placeholder="Enter your own last name" required />
                                    </div>

                                    <div className="mb-1">
                                        <label htmlFor="gender" className="form-label">Date of Birth</label>
                                        <div className="d-flex gap-3">
                                            <input ref={refBirthDateDay} id="birthDateDay" name="birthDateDay" type="text" onChange={handleFormChangeDate} className="form-control" placeholder="DD" style={{border: invalidBirthDay ? invalidBorder : ""}} />
                                            <input ref={refBirthDateMonth} id="birthDateMonth" name="birthDateMonth" type="text" onChange={handleFormChangeDate} className="form-control" placeholder="MM" style={{border: invalidBirthMonth ? invalidBorder : ""}} />
                                            <input ref={refBirthDateYear} id="birthDateYear" name="birthDateYear" type="text" onChange={handleFormChangeDate} className="form-control" placeholder="YYYY" style={{border: invalidBirthYear ? invalidBorder : ""}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`form-screen form-content form-content-${formStep === 2 ? 'current' : (formStep < 2 ? 'prev' : 'next')} justify-content-center`} style={conditionalStyle}>
                            <div className="d-flex flex-column gap-2 align-items-center">
                                <img src="/images/fl-login-tick.svg" />
                                <div className="login-success-txt d-flex flex-column gap-1">
                                    <p>Account Created</p>
                                    <p>Welcome to Forever Loved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    {formStep === 0 &&
                        <div className="d-flex flex-column align-items-center w-100">
                            <button className="btn fl-btn-large w-100" onClick={handleClickNext}>Next</button>
                            <p className="fl-txt-home-subheading mt-3 float-center">I already have an account. <span onClick={handleClickLogin} style={{textDecoration: "underline", cursor: "pointer"}}>Login</span></p>
                        </div>
                    }

                    {formStep === 1 &&
                        <div className="d-flex flex-column align-items-center w-100">
                            <button className="btn fl-btn-large w-100" onClick={handlePost}>Next</button>
                            <p className="fl-txt-home-subheading mt-3 float-center">I already have an account. <span onClick={handleClickLogin} style={{textDecoration: "underline", cursor: "pointer"}}>Login</span></p>
                        </div>
                    }

                    {formStep === 2 &&
                        <button className="btn fl-btn-large w-100" onClick={handleClose}>Continue</button>
                    }
                </Modal.Footer>

            </Modal>
        </>
    );
}
