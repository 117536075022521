import React, { useContext, useEffect, useState } from "react";
import { MemorialTab, MemorialType } from "../components/AppType";
import { Header } from "../components/Header/Header";
import { SetupWidget } from "../components/SetupWidget/SetupWidget";
import { TreeOfLife } from "../components/TreeOfLife/TreeOfLife";
import { Footer } from "../components/Menu/Menu";
import { LifeStory } from "../components/LifeStory/LifeStory";
import { Tombstone } from "../components/Tombstone/Tombstone";
import { Home } from "../components/Home/Home";
import { AppContext } from "../AppContext";
import { CSSTransition } from 'react-transition-group';
import { MemorialContext } from "../MemorialContext";
import { useTranslation } from "react-i18next";

export const MemorialJourney: React.FC = () => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Active tab represents the current selected tab. Default is home
    const [activeTab, setActiveTab] = useState<MemorialTab>(MemorialTab.home);
    const isActiveTab = (pageId: MemorialTab): number => {
        const active: number = 1;
        const inactive: number = 0;
        if (activeTab === pageId) {
            return active;
        }
        return inactive;
    }
    const handleNavClick = (pageId: MemorialTab) => {
        setActiveTab(pageId);
    }

    // This is the Memorial Setup Widget to display creation progress
    const [setupStatusComplete, setSetupStatusComplete] = useState<boolean>(false);      

    return(
        <>
            <div id="pageWrapper" className="page-wrapper">

                {isActiveTab(MemorialTab.home) == 1 &&
                    <CSSTransition in={true} timeout={2000} appear={true} classNames="transitionFade">
                        <Home activeTab={activeTab} setActiveTab={setActiveTab} isActiveTab={isActiveTab} handleNavClick={handleNavClick} />
                    </CSSTransition>
                }

                {isActiveTab(MemorialTab.home) !== 1 &&
                    <>
                        <CSSTransition in={true} out={true} timeout={2000} appear={true} classNames="transitionFade">
                            <Header activeTab={activeTab} setActiveTab={setActiveTab} isActiveTab={isActiveTab} />
                        </CSSTransition>

                        {setupStatusComplete &&
                            <CSSTransition in={true} out={true} timeout={2000} appear={true} classNames="transitionFade">
                                <SetupWidget />
                            </CSSTransition>
                        }
                        
                        <CSSTransition in={true} out={true} timeout={2000} appear={true} classNames="transitionFade">
                            <Footer activeTab={activeTab} setActiveTab={setActiveTab} isActiveTab={isActiveTab}  handleNavClick={handleNavClick} />
                        </CSSTransition>

                        {isActiveTab(MemorialTab.tab1) === 1 &&
                            <CSSTransition in={true} out={true} timeout={2000} appear={true} classNames="transitionFade">
                                <LifeStory />
                            </CSSTransition>
                        }

                        {isActiveTab(MemorialTab.tab2) === 1 &&
                            <CSSTransition in={true} out={true} timeout={2000} appear={true} classNames="transitionFade">
                                <TreeOfLife />
                            </CSSTransition>
                        }

                        {isActiveTab(MemorialTab.tab3) === 1 &&
                            <CSSTransition in={true} out={true} timeout={2000} appear={true} classNames="transitionFade">
                                <Tombstone />
                            </CSSTransition>
                        }
                    </>
                }

            </div>        
        </>
    );
}