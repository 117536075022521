import React, { useContext } from "react";
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../AppContext";
import { ASSET_URL } from "../../utils/AppUtil";

type LifeEventProps = {
    title: string,
    message: string,
    eventDateDisplay: string,
    images: string[] | undefined,
    locations: string[] | undefined
}

export const LifeEventDetailed: React.FC<LifeEventProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const click = () => {
        console.log(props.locations);
    }
    
    return (
        <div className="modal-life-event modal fade" id="lifeEventDetailed" tabIndex={999} aria-labelledby="lifeEventDetailed" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen" style={{zIndex: "999", backgroundColor: "#00222F"}}>
                <div className="modal-content" style={{backgroundColor: "#00222F"}}>
                    <div className="modal-body scroll-active" style={{scrollbarWidth: "none", backgroundColor: "#00222F", overflow: "auto"}}>
                        <div className="d-flex justify-content-between mb-5">
                            <img src="/images/fl-arrow-back.svg" data-bs-dismiss="modal" aria-label="Close" width="21px" style={{cursor: "pointer"}}/>
                            <div onClick={click} className="fl-btn-share" style={{width: "auto", cursor: "pointer"}}>
                                <img src="/images/fl-share.svg" />
                                <p>Share</p>
                            </div>
                        </div>
                    
                        <div className="d-flex flex-column gap-2">

                            <div className="d-flex gap-2 mb-4">
                                {props.locations?.map((location, index) => {
                                    return (
                                        <div key={index} className="timeline-location-pill d-flex gap-2 px-2 py-1">
                                            <img src="/images/fl-map-pin.svg" />
                                            <p>{location}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            
                            <p className="fl-txt-title">{props.title}</p>
                            <p className="fl-life-event-date">{props.eventDateDisplay}</p>
                            <p className="fl-txt-subtitle">{props.message}</p>
                        </div>

                        <div className="life-event-detailed-image-wrapper d-flex flex-column align-items-center gap-5 mt-5">

                            {props.images?.map((image, index) => {
                                return (
                                    <img key={index} src={`${ASSET_URL}/public/picture/${image}`} className="life-event-detailed-image" />
                                )
                            })}
                        </div>

                    </div>
                
                </div>
            </div>
        </div>
    );
}