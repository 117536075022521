import React, { ReactNode, Suspense, createContext, useContext, useEffect, useState } from 'react';
import { debug } from './utils/AppUtil';
import { Auth } from './components/AppType';

// Define types for your context data
interface AppContextData {
  commonData: Auth;
  setCommonData: React.Dispatch<React.SetStateAction<Auth>>;
}

// Create a context object
export const AppContext = createContext<AppContextData | undefined>(undefined);

// Create a provider component
export const AppProvider: React.FC<{children: ReactNode}> = (props) => {
  const authDefault: Auth = {
      token: 'FREE',
      userId: '',
      firstName: 'Tommy',
      lastName: 'Stephens',
      username: 'Tommy_Stephens',
      email: 'tommy@acme.com',
      cellphone: '0820'
  }

  const [commonData, setCommonData] = useState<Auth>(authDefault); // initial data

  useEffect(() => {
    debug("Auth Token", JSON.stringify(commonData));
  }, [commonData]);

  return (
    // <Suspense fallback={
    //   <div className="spinner-border text-light" role="status">
    //       <span className="visually-hidden">Loading...</span>
    //   </div>}
    // >
      <AppContext.Provider value={{ commonData, setCommonData }}>
        {props.children}
      </AppContext.Provider>
    // </Suspense>
  );
};

