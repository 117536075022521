import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { ForeverLovedDaysType, MemorialTab, MemorialType, PageResultType } from "../AppType";
import { useTranslation } from "react-i18next";
import { fetchRecords, debug, ASSET_URL, BASE_URL } from "../../utils/AppUtil";
import { AppContext } from "../../AppContext";
import { UserAccount } from "../Modals/ModalUserAccount";
import { ModalCreateMemorial } from "../Modals/ModalCreateMemorial";
import { AddToHome } from "../Modals/ModalAddToHome";
import { MemorialContext } from "../../MemorialContext";
import 'animate.css';

type HomeProps = {
    activeTab: MemorialTab,
    setActiveTab: Dispatch<SetStateAction<MemorialTab>>,
    isActiveTab:  (pageId: MemorialTab) => number,
    handleNavClick: (pageId: MemorialTab) => void
}

export const Home: React.FC<HomeProps> = (props) => {

    //BUILD UPDATE
    const [buildNum, setBuildNum] = useState<number>(2);
    
    // Internationalisation
    const { t, i18n } = useTranslation();

    // Authentication data from AppContext
    const { commonData } = useContext(AppContext)!;
    
    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;
    
    const [memorials, setMemorials] = useState<React.ReactElement[]>();
    const [foreverLovedDays, setForeverLovedDays] = useState<React.ReactElement[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [emptyState, setEmptyState] = useState<boolean>(false)
    
    const [language, setLanguage] = useState<string>("en");
    
    const gbFlag: string = "/images/flags/flag-gb.svg";
    const frFlag: string = "/images/flags/flag-fr.svg";
    const [currentFlag, setCurrentFlag] = useState(gbFlag);

    const [splashShow, setSplashShow] = useState<boolean>(false);
    const [splashAnimation, setSplashAnimation] = useState('');

    const [FLDays, setFLDays] = useState<boolean>(false);

    // [image] will be the image ID
    // [color] is used for the heart & background-colors (different gradients applied in JSX)
    // const foreverLovedDays = [
    //     {
    //         image: "fl-days-img-1.svg",
    //         name: "Gerard Smith",
    //         connection: "Benton Smith",
    //         relationship: "Brother",
    //         type: "Father’s Day",
    //         day: "Today",
    //         color: "#D63384"
    //     },
    //     {
    //         image: "fl-days-img-2.svg",
    //         name: "Noam Halevi",
    //         connection: "Thomas Halevi",
    //         relationship: "Son",
    //         type: "Day of Passing",
    //         day: "5 days",
    //         color: "#FD9843"
    //     },
    //     {
    //         image: "fl-days-img-3.svg",
    //         name: "Derek Taylor",
    //         connection: "Ronan Taylor",
    //         relationship: "Father",
    //         type: "Birthday",
    //         day: "1 day",
    //         color: "#198754"
    //     },
    //     {
    //         image: "fl-days-img-4.svg",
    //         name: "Bethany Lawrence",
    //         connection: "Thomas Lawrence",
    //         relationship: "Mother",
    //         type: "Yahrzeit",
    //         day: "Today",
    //         color: "#8540F5"
    //     }
    // ]

    const changeLanguage = () => {
        if(language === "en") {
            setLanguage("fr");
            setCurrentFlag(frFlag)
            i18n.changeLanguage("fr");
        } else {
            setLanguage("en");
            setCurrentFlag(gbFlag)
            i18n.changeLanguage("en");
        }
    }

    // HOMEPAGE DISPLAY ----------
    useEffect(() => {

        fetchRecords(commonData.token, 'memorial/homepage', processMemorialData)
        .catch((error) => {
            setIsLoading(false);
            debug('fetchRecords error: ' + error, 'Home');
        });

        fetchRecords(commonData.token, 'user/loggedIn/dates', processForeverLovedDaysData)
        .catch((error) => {
            setIsLoading(false);
            debug('fetchRecords error: ' + error, 'Home');
        });

    }, [memorialData, language]);

    // Simulate click to combat mobile touch swipe bug
    useEffect(() => {
        document.getElementById("discoverCommunitiesSlide1")?.click();
    }, []);
    

    const processMemorialData = (responseJson: PageResultType) => {
        setIsLoading(true);
        setMemorials(buildMemorialsJSX(responseJson));
        setIsLoading(false);

        if (responseJson.numRecords === 0) {
            setEmptyState(true);
        }
    }

    const buildMemorialsJSX = (response: PageResultType): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
        let key: number = 0;

        response.data.forEach((memorial: MemorialType) => {

            let profilePictureSrc = "images/fl-avatar-min.svg";
            if (memorial.memorialPictureId) {
                profilePictureSrc = `${ASSET_URL}/public/picture/${memorial.memorialPictureId}`
            }

            let backgroundImg = "/images/covor_photo_default_Small.png";
            if (memorial.coverPictureId) {
                backgroundImg = `${ASSET_URL}/public/picture/${memorial.coverPictureId}`
            }

            result.push(

                <div key={key} className="home-memorial-tile" style={{cursor: "pointer"}}>

                    {memorial.role != "VIEWER" && 
                        <div className="home-tile-badge">
                            {memorial.role === "CREATOR" &&
                                <p>{t("Home.Memorials.roleOwner", {ns: "components"})}</p>
                            }
                            {memorial.role === "ADMIN" &&
                                <p>{t("Home.Memorials.roleAdmin", {ns: "components"})}</p>
                            }
                            {memorial.role === "EDITOR" &&
                                <p>{t("Home.Memorials.roleEditor", {ns: "components"})}</p>
                            }
                        </div>
                    }

                    <div onClick={() => {setMemorialData(memorial); props.handleNavClick(MemorialTab.tab1);}} 
                        className="home-memorial-tile-body d-flex flex-column align-items-center p-3 memorial-tile-profile-cover"
                        style={{backgroundImage: `linear-gradient(rgba(0, 34, 47, 0) -100%, rgb(6, 45, 60) 50%), url('${backgroundImg}')`}}
                    >
                        <div className="memorial-tile-profile-picture-wrapper mb-3">
                            <img src={profilePictureSrc} className="memorial-tile-profile-picture" width="148" height="148" alt="" />
                        </div>
                        <p>{memorial.firstName + ' ' + memorial.lastName}</p>
                        <p>{memorial.birthYear} - {memorial.deathYear}</p>
                    </div>
                    
                </div>
            );
            key++;
        });

        return result;
    }

    const processForeverLovedDaysData = (responseJson: ForeverLovedDaysType[]) => {
        setIsLoading(true);
        setForeverLovedDays(buildForeverLovedDaysJSX(responseJson));
        setIsLoading(false);
    }

    const buildForeverLovedDaysJSX = (response: ForeverLovedDaysType[]): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
        let key: number = 0;

        const today = (new Date()).getTime();
        const msDay = 24 * 60 * 60 * 1000; // milliseconds per day
        
        let date;
        let formattedDate;

        response.forEach((day: ForeverLovedDaysType) => {

            date = (new Date(day.date)).getTime();
            formattedDate = Math.floor((date - today) / msDay);

            let dayColor = "";
            let dayText = "";

            if (day.dateType === "BIRTH") {
                dayColor = "#198754";
                dayText = "Birthday";

            } else if (day.dateType === "DEATH") {
                dayColor = "#FD9843";
                dayText = "Day of Passing";

            } else if (day.dateType === "YAHRZEIT") {
                dayColor = "#8540F5";
                dayText = "Yahrzeit";

            } else if (day.dateType === "FATHERS_DAY") {
                dayColor = "#D63384";
                dayText = "Father's Day";

            } else if (day.dateType === "MOTHERS_DAY") {
                dayColor = "#D63384";
                dayText = "Mother's Day";
            }

            let pictureSrc = "images/fl-avatar-min.svg";
            if (day.memorialPictureId) {
                pictureSrc = `${ASSET_URL}/public/picture/${day.memorialPictureId}`
            }

            result.push(
                <div key={key} className="fl-day" style={{background: `linear-gradient(to bottom right, ${dayColor}1A 0%, #0527341A 100%) 100% no-repeat`}}>
                    <img src={pictureSrc} width={56} height={56} />

                    <div>
                        <p className="fl-day-name">{day.memorialFirstName} {day.memorialLastName}</p>
                        <p className="fl-day-connection">{day.relationship} of <span>{day.userFirstname} {day.userLastname}</span></p>
                    </div>

                    <div className="w-100">
                        <div className="fl-day-type" style={{backgroundColor: dayColor + '33'}}>
                            <div className="d-flex gap-2">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginTop: "2px"}}>
                                    <g clip-path="url(#clip0_10976_74125)">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00008 0.985539C9.32858 -2.43596 17.6506 3.55129 6.00008 11.25C-5.65042 3.55204 2.67158 -2.43596 6.00008 0.985539Z" fill={dayColor}/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_10976_74125">
                                            <rect width="12" height="12" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>

                                <p>{dayText}</p>
                            </div>

                            {day.dateType === "YAHRZEIT" &&

                                <>
                                    <div className="dropdown-center" style={{position: 'static'}}>

                                        {/* data-bs-display="static" */}
                                        <img className="dropdown-toggle" src="/images/fl-icon-info.svg" data-bs-toggle="dropdown" aria-expanded="false" />

                                        <div className="dropdown-menu yahrzeit-dropdown">
                                            <img src="/images/fl-dropdown-triangle.svg" />
                                            <p className="mb-1"><b>What is a Yahrzeit?</b></p>
                                            <p>A Jewish tradition marking the anniversary of a loved one's death, observed with prayers, memorial candles, and acts of remembrance.</p>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                            
                        <div className="fl-day-day">
                            {formattedDate === 0 ?
                                <p>Celebrating <span>Today</span></p>
                                :
                                <p>Celebrating in <span>{formattedDate} days</span></p>
                            }
                            
                        </div>
                    </div>
                </div>
            );
            key++;
        });

        return result;
    }


    return (
        <>
            {splashShow &&
                <div className={`splash-loading-div ${splashAnimation}`}>
                    <img src="/images/fl-logo-small.svg" className="mb-3" width={100} height={100} alt="" />
                    <p>Forever Loved</p>
                    <p>The final resting place online</p>
                </div>
            }

            <div className="page-wrapper">

                <div className="d-flex justify-content-between p-3">
                    <UserAccount />
                    <p className="fl-txt-name" onClick={() => setFLDays(c => !c)}>{ t("Home.title", {ns: "components"}) }</p>
                    {/* <img src="/images/fl-bell.svg" className="home-icon-top p-2" height={40} alt="" /> */}
                    <AddToHome />
                </div>

                {emptyState &&
                    <>
                        <div className="homepage-empty-wrapper d-flex flex-column justify-content-between p-3">
                            <div className="homepage-empty-images d-flex justify-content-center">
                                <img className="px-3" src="/images/home-img-1.png" alt="" />
                                <img className="px-3 animate__animated animate__slideInRight animate__delay-2s animate__slow" src="/images/home-img-2.png" alt="" />
                                <img className="px-3 animate__animated animate__slideInLeft animate__delay-5s animate__slow" src="/images/home-img-3.png" alt="" />
                            </div>

                            <div className="homepage-empty-bottom d-flex flex-column justify-content-between gap-3">
                                <p>Memorials</p>
                                <p>Memorials remember those who have passed away. They include key life events, memories and so much more.</p>
                                {/* <button className="btn fl-btn-large mt-3" data-bs-toggle="modal" data-bs-target="#createMemorial" style={{cursor: "pointer"}}>Create a memorial</button> */}
                                <ModalCreateMemorial emptyState={true} />
                            </div>
                        </div>
                    </>
                }

                {!emptyState &&
                    <>
                        {/* <span className="flag-icon flag-icon-us"></span> */}
                        {/* <div className="px-3 mb-3"> */}

                            {/* Internationalisation */}                
                            {/* <div className="reoccuring-donation-switch form-check form-switch temp">
                                <input className="form-check-input m-0" type="checkbox" role="switch" id="toggleLanguageSwitch" onClick={() => changeLanguage()} placeholder="" title=""/>
                                <label className="form-check-label" htmlFor="toggleLanguageSwitch"><img src={currentFlag} alt="" /></label>
                                <p className="build-text">Build #{buildNum}</p>
                            </div> */}
                        {/* </div> */}

                        {FLDays ?
                            <>
                                <div className="d-flex gap-3">
                                    <p className="fl-txt-home-subheading ps-3" style={{whiteSpace: "nowrap"}}>Introducing Forever Loved Days</p>
                                    <div className="home-memorials-heading-line">&nbsp;</div>
                                </div>

                                <div className="p-3 mb-3">
                                    <div className="card-container-top d-flex justify-content-center">
                                        <img src="/images/fl-days-video.png" width="100%" />
                                    </div>

                                    <div className="card-container-bottom" style={{backgroundColor: '#FFFFFF0D'}}>
                                        <div className="d-flex flex-column gap-3">
                                            <p className="fl-days-intro">Turn on contact uploading to celebrate more Forever Loved days</p>
                                            <div className="d-flex gap-3">
                                                <div className="fl-days-btn-1"><p>Not now</p></div>
                                                <div className="fl-days-btn-2"><p>Turn on</p></div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="d-flex gap-3">
                                    <p className="fl-txt-home-subheading ps-3" style={{whiteSpace: "nowrap"}}>Upcoming Forever Loved Days</p>
                                    <div className="home-memorials-heading-line">&nbsp;</div>
                                </div>

                                <div className="fl-days-wrapper pe-3 mb-3">

                                    {foreverLovedDays}

                                    <div className="fl-day fl-day-add">
                                        <div className="d-flex flex-column justify-content-center align-items-center gap-2 h-100">
                                            <img src="/images/fl-logo-small.svg" />
                                            <p className="add-connections-txt">Add connections to see more <b>Forever Loved days</b></p>
                                        </div>
                                        <div className="btn fl-btn-dark w-100 fw-bold" style={{padding: "12px"}}>Add connections</div>
                                    </div>
                                </div>
                            </>
                        }

                        

                        <div className="d-flex gap-3">
                            <p className="fl-txt-home-subheading ps-3" style={{whiteSpace: "nowrap"}}>{ t("Home.Memorials.heading", {ns: "components"}) }</p>
                            <div className="home-memorials-heading-line">&nbsp;</div>
                        </div>

                        <div className="d-flex flex-column justify-content-between">

                            {/* Memorials */}
                            {/* <div className="py-3 pe-0 h-100"> */}
                                <div className="home-memorials d-flex flex-column gap-3 p-3 h-100">
                                    {memorials}
                                    <ModalCreateMemorial emptyState={false} />
                                </div>
                            {/* </div> */}

                            {/* <div className="px-3">
                                
                            </div> */}
                        </div>

                    </>
                }
            </div>
        </>
    );
}