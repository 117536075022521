import React, { RefObject, useContext, useEffect, useRef, useState } from "react";
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../AppContext";
import { ASSET_URL } from "../../utils/AppUtil";

type MemoryProps = {
    memorySelected: boolean,
    memoryClicked: number,
    title: string,
    message: string,
    images: string[] | undefined
}

export const MemoryDetailed: React.FC<MemoryProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData } = useContext(MemorialContext)!;

    // Images array state
    const [memoryImages, setMemoryImages] = useState([""]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [activeClass, setActiveClass] = useState<string>("")
    const [completedClass, setCompletedClass] = useState<string>("")
    const [rotationComplete, setRotationComplete] = useState<string>("")
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [intervalId, setIntervalId] = useState<number | null>(null);

    const firstBarRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

    // Add event listeners to tap into Bootstrap modal handling functions
    useEffect(() => {
        const modalElement = document.getElementById('MemoryDetailed');
        if (modalElement) {
          modalElement.addEventListener('shown.bs.modal', handleModalOpen);
          modalElement.addEventListener('hidden.bs.modal', handleModalClose);
          
          return () => {
            modalElement.removeEventListener('shown.bs.modal', handleModalOpen);
            modalElement.removeEventListener('hidden.bs.modal', handleModalClose);
          };
        }
      }, []);

    // Verify image are not undefined
    useEffect(() => {
        if (props.images) {
            setMemoryImages(props.images);
        }
    }, [props.images]);

    // Handle modal open state
    useEffect(() => {
        if (isModalOpen) {
            // if (firstBarRef.current) {
            //     firstBarRef.current.classList.remove('active');
            //     firstBarRef.current.classList.add('active');
            // }
            setCurrentIndex(0);
            setTimeout(() => {
                setActiveClass("active");
                setCompletedClass("completed");
            }, 200);
        }
    }, [isModalOpen]);

    // setInterval to rotate through the images
    useEffect(() => {
        if (intervalId !== null) {
            clearInterval(intervalId);
        }

        const interval = setInterval(() => {
            setTimeout(() => {

                setCurrentIndex((prevIndex) => {
                    const currentIndex = (prevIndex + 1) % memoryImages.length;
                    const lastIndex = memoryImages.length - 1;

                    // Reset bars at the start of each rotation
                    if (currentIndex === 0) {
                        setActiveClass("");
                        setCompletedClass("");
                        setRotationComplete("completed-bg");
    
                        setTimeout(() => {
                            setActiveClass("active");
                            setCompletedClass("completed");
                            setRotationComplete("");
                        }, 10);
    
                    } else {
                        setActiveClass('active');
                        setCompletedClass('completed');
                    }
    
                    return currentIndex;
                });
            }, 1000)
            
        }, 6000);

        setIntervalId(interval as unknown as number);

        return () => clearInterval(interval);
    }, [memoryImages]);


    const handleModalClose = () => {
        setIsModalOpen(false);
        setCurrentIndex(0);

        if (intervalId !== null) {
          clearInterval(intervalId);
        }
    }
    
    const handleModalOpen = () => {
        setIsModalOpen(true);
    }

    return (
        <div className="modal-life-event modal fade" id="MemoryDetailed" tabIndex={999} aria-labelledby="MemoryDetailed" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen" style={{zIndex: "999", backgroundColor: "#00222F"}}>
                <div className="modal-content" style={{backgroundColor: "#00222F"}}>
                    <div className="modal-body p-0" style={{scrollbarWidth: "none", backgroundColor: "#00222F"}}>

                        <div className="memory-gradient-top"></div>
                        <div className="memory-gradient-bottom"></div>

                        {/* <p style={{color: 'white'}}>currentIndex: {currentIndex} | currentIndex: {currentIndex}</p> */}
                        <div className="memory-bars">
                            {memoryImages.map((_, index) => (
                                <div key={index} className={`memory-bar ${rotationComplete}`}>
                                    <div ref={index === 0 ? firstBarRef : null} className={`progress ${index <= currentIndex ? (index < currentIndex ? completedClass : activeClass) : ''}`}></div>
                                </div>
                            ))}
                        </div>

                        <div className="memory-content-top">
                            <div className="d-flex align-items-center gap-3">
                                <img src="/images/fl-avatar-min.svg" width={36} height={36} />
                                <div className="memory-name d-flex flex-column">
                                    <p>A memory from</p>
                                    <p>{commonData.firstName} {commonData.lastName}</p>
                                </div>
                            </div>

                            <img src="/images/memory-modal-close.svg" onClick={handleModalClose} data-bs-dismiss="modal" aria-label="Close" />
                        </div>

                        <div className="memory-content-bottom p-4">
                            <div className="memory-text d-flex flex-column gap-4 pe-3">
                                <p>{props.title}</p>
                                <p>{props.message}</p>
                            </div>
                            <div className="d-flex flex-column gap-4">
                                <img src="/images/memory-edit-btn.svg" />
                                <img src="/images/memory-share-btn.svg" />
                                <img src="/images/memory-audio-btn.svg" />
                            </div>
                        </div>

                        <div className="memory-images-background">
                            {memoryImages.map((image, index) => (
                                <>
                                    <div className={`memory-background-blur ${index === currentIndex ? activeClass : 'hidden'}`} style={{backgroundImage: `url(${ASSET_URL}/public/picture/${image})`}}></div>
                                    <div key={index} className={`memory-img-wrapper`}>
                                        <img
                                            className={`memory-img ${index === currentIndex ? activeClass : 'hidden'}`}
                                            src={`${ASSET_URL}/public/picture/${image}`}
                                        />
                                    </div>
                                </>
                            ))}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}